@import './variables.css';

@import './overrides.css';

@import './components.scss';

@import './pb-styles.scss';

@import './common-blocks.scss';

@import './blog.scss';

@import './rsd.scss';

@import './utilities.css';
@tailwind components;
@tailwind utilities;
@tailwind base;
/*
  Base styles for the project.
  ----------------------------
  * Styles for body and html
  * Styles for headings
  * Font definitions
 */
@layer base {
    *,
    *:before,
    *:after {
        @apply box-border;
        -webkit-tap-highlight-color: transparent;
    }

    html {
        height: 100%;
        touch-action: manipulation;
        font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body {
        font-family: var(--font-sans);
        font-size: var(--base-font);
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @apply bg-primary text-grey-1 font-primary box-border;
    }

    body {
        @apply relative min-h-full m-0 overflow-x-hidden text-sm leading-6 font-medium;
    }

    a {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);  
    }

    p a {
        @apply underline hover:no-underline;
    }

    h1,
    h2,
    h3 {
        @apply font-secondary font-bold uppercase text-black;
    }

    h4,
    h5,
    h6 {
        @apply font-primary font-extrabold;
    }

    h1 {
        /* use tailwind selectors in order to make sure that all of the sizes and classes
    are the same in every place, meaning that any jsx element with className="text-2xl"
    & has the same size as h1 headings */
        @apply text-4xl leading-[3rem] -tracking-[0.03125rem] md:text-[3.25rem] md:leading-[4.25rem];
    }

    h2 {
        @apply text-2xl leading-[1.875rem] -tracking-[0.03125rem] md:text-[2.5rem] md:leading-[3.25rem];
    }

    h3 {
        @apply text-xl leading-[1.625rem] font-primary font-extrabold md:font-secondary md:font-bold md:text-2xl md:leading-[1.9375rem];
    }

    h4 {
        @apply text-lg leading-[1.625rem] md:leading-[1.6875rem];
    }

    /* Should not be used at all */
    h5 {
        @apply text-base leading-[1.6875rem];
    }

    h6 {
        @apply text-sm leading-[1.125rem] normal-case md:uppercase md:leading-[1.0625rem];
    }

    .megamenu-open {
        @apply overflow-hidden h-screen lg:overflow-auto lg:h-full;
    }
    .megamenu-open body {
        @apply h-screen overflow-hidden relative lg:overflow-auto lg:h-full;
    }
    .zoom-open,
    .zoom-open body {
        @apply overflow-hidden h-full;
    }
    .modal-open-mob {
        @apply overflow-hidden md:overflow-auto;

        & .nav-sticky {
            @apply z-0 md:z-50;
        }
    }

    .zoom-open .nav-sticky {
        @apply z-0 !important;
    }
    .btn-sm {
        @apply py-1.5 !important;
    }
    .link {
        @apply underline;
        &.disabled {
            @apply select-none opacity-50 pointer-events-none;
        }
    }
    .edit-cart-btn-group {
        & > button:disabled {
            @apply text-primary;
        }
    }
    /* Toggle Class */ 
    .toggle-wrapper {
        @apply w-[1.8125rem] relative inline-block mr-2 align-middle select-none transition duration-200 ease-in;
    }
    .toggle-checkbox {
        @apply absolute top-0.5 left-0.5 block w-2.5 h-2.5 rounded-full bg-gold-1 border-0 appearance-none cursor-pointer;
    }
    .toggle-label {
        @apply h-3.5 bg-white border-black block overflow-hidden rounded-full border border-solid cursor-pointer;
    }
    .toggle-checkbox:checked {
        @apply bg-white left-auto right-0.5;
    }
    .toggle-checkbox:checked + .toggle-label {
        @apply bg-gold-1 border-gold-1;
    }
    .toggle-checkbox:disabled {
        @apply opacity-100 left-0.5 right-auto pointer-events-none cursor-default;
        background-color: rgb(130 130 130 / 50%);
    }
    .toggle-checkbox:disabled + .toggle-label {
        @apply bg-white border-grey-9 pointer-events-none cursor-default;
    }

    /* Listing */   

    .list-disc,
    .list-decimal {
        @apply list-inside py-3;
    }
    .list-disc {
        @apply list-none;
    }
    .list-disc li {
        @apply pl-3 relative;
        &:before {
            @apply content-[''] w-[0.1875rem] h-[0.1875rem] bg-current rounded-full absolute top-3 left-0 z-0;
        }
    }

    .text-large {
        @apply text-lg leading-[1.8125rem];
    }
    .text-small {
        @apply text-xs leading-[1.125rem] text-black;
    }
    .text-caption {
        @apply text-black text-xs leading-[0.875rem] tracking-[0.03125rem] uppercase font-bold;
    }
    div[class *= "text_root"] a {
        @apply text-current;
    }
    /* Disabled the Chat Link for the Internal Website */
    .usgb_admin .chat-link {
        @apply pointer-events-none;
    }
}
