/* Page Builder Styles */
a.btn-primary, a.btn-secondary {
    @apply inline-block no-underline w-auto text-center !important;
}
div[class*="banner_wrapper"], div.wrapper {
    @apply flex-col relative justify-center items-start;
    div[class*="banner_content"] {
        @apply static p-[1.875rem];
    }
    div[class*="banner_button"] {
        @apply z-10 w-auto;
    }
}
picture[class*="banner_overlay"] {
    @apply absolute inset-0 w-full h-full max-w-full;
    img[class*="banner_image"] {
        @apply w-full h-full object-cover;
    }
    ~ div[class*="banner_content"], ~ div[class*="banner_button"] {
        @apply mx-auto;
    }
    &[class*="banner_collageLeftOverlay"] {
        ~ div[class*="banner_content"], ~ div[class*="banner_button"] {
            @apply mr-auto ml-0;
        }
    }
    &[class*="banner_collageRightOverlay"] {
        ~ div[class*="banner_content"], ~ div[class*="banner_button"] {
            @apply ml-auto mr-0;
        }
    }
    &:not([class*="banner_posterOverlay"]) {
        ~ div[class*="banner_content"] {
            @apply md:max-w-[33.75rem];
        }
    }
}
div[class*="banner_overlay"] {
    @apply w-auto h-auto;
    .banner-inner-content {
        @apply md:max-w-[33.75rem];
    }
    &[class*="banner_collageLeftOverlay"] {
        .banner-inner-content {
            @apply mr-auto ml-0;
        }
    }
    &[class*="banner_collageRightOverlay"] {
        .banner-inner-content {
            @apply ml-auto mr-0;
        }
    }
}
main div[class*="buttons_root"] {
    a {
        @apply relative pt-0 px-0 pb-[0.0625rem] no-underline uppercase font-bold text-xs leading-[0.875rem] tracking-[0.03125rem] text-blue-1 hover:text-hover focus:shadow-none;
        &:after {
            @apply content-[''] absolute bottom-0 inset-x-0 z-0 border-b border-solid border-current;
        }
    }
    .btn-link-type-2 a {
        @apply text-white hover:text-white;
        &:hover {
            &:after {
               @apply border-b-[0.1875rem];
            }
        }
    }
}
div[class*="video_container"] {
    article.yt-lite {
        @apply absolute inset-0;
    }
}
div[class*="tabs_root"] {
    ul[class*="tabs_navigation"] {
        @apply flex flex-wrap p-0 md:flex-nowrap;
        li[class*="tabs_header"] {
            @apply flex-grow md:max-w-[17.25rem] bg-white border-0 px-2.5 py-[0.9375rem] font-primary font-extrabold text-sm leading-[1.0625rem] uppercase no-underline text-hover text-center hover:text-hover;
            &[class*="tabs_selected"] {
                @apply bg-grey-4 text-blue-1 hover:text-blue-1;
            }
        }
    }
    div[class*="tabs_content"] {
        @apply border border-solid border-grey-4 bg-white min-h-0 mt-0;
    }
    div[class*="tabs_panel"] > div[class*="tabItem_root"] {
        @apply  p-5 lg:p-[1.875rem];
    }
    .dark-tabs & {
        ul[class*="tabs_navigation"] {
            li[class*="tabs_header"] {
                @apply bg-transparent text-blue-3 hover:text-blue-3;
                &[class*="tabs_selected"] {
                    @apply bg-transparent text-white hover:text-white;
                }
            }
        }
    }
}
picture {
    div[class*="banner_overlayBackground"] {
        @apply hidden;
    }
}
.pagebuilder-mobile-hidden {
    @apply hidden md:block;
}
.pagebuilder-mobile-only {
    @apply block md:hidden;
}
.pagebuilder-column-group {
    @apply flex flex-wrap md:flex-nowrap;
    .pagebuilder-column {
        @apply basis-full md:basis-auto;
    }
}
.pagebuilder-slider {
    @apply px-7;
}
.slick-slider {
    .wrapper {
        @apply flex flex-col relative justify-center items-start;
        picture {
            @apply absolute inset-0 w-full h-full max-w-full z-0;
            img[class*="banner_image"] {
                @apply w-full h-full object-cover;
            }
            ~ div[class*="banner_content"], ~ div[class*="banner_button"] {
                @apply mx-auto;
            }
            &[class*="banner_collageLeftOverlay"] {
                ~ div[class*="banner_content"], ~ div[class*="banner_button"] {
                    @apply mr-auto ml-0;
                }
            }
            &[class*="banner_collageRightOverlay"] {
                ~ div[class*="banner_content"], ~ div[class*="banner_button"] {
                    @apply ml-auto mr-0;
                }
            }
        }
        div[class*="banner_content"] {
            @apply relative z-2;
            a.btn-primary {
                @apply inline-block text-white no-underline w-auto text-center !important;
            }
        }
        div[class*="banner_button"] {
            @apply z-10 w-auto;
        }
    }
    .slick-track {
        @apply flex;
    }
    .slick-prev,
    .slick-next {
        @apply w-[3.125rem] h-[3.125rem] z-1;
        &:before {
            background-image: url('data:image/svg+xml,<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="23.5" fill="%23001F3D" stroke="%23001F3D" stroke-width="3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M29.5133 24.2041L21.7266 16.1626C21.2733 15.7235 20.5799 15.7235 20.1533 16.1626C19.7266 16.6018 19.7266 17.3428 20.1533 17.7819L27.1666 25L20.1533 32.218C19.7266 32.6846 19.7266 33.3982 20.1533 33.8373C20.5799 34.2764 21.2733 34.2764 21.7266 33.8373L29.5133 25.8233C29.9399 25.3567 29.9399 24.6432 29.5133 24.2041Z" fill="white" stroke="white" stroke-width="0.5"/></svg>');
            @apply content-[''] inline-block bg-no-repeat bg-contain w-[3.125rem] h-[3.125rem] opacity-100;
        }
        &.slick-disabled {
            @apply cursor-default;
            &:before {
                @apply opacity-50;
            }
        }
    }
    .slick-prev {
        &:before {
            @apply rotate-180;
        }
    }
    .slick-dots {
        @apply bottom-0 inset-x-0 w-auto pl-0;
        li {
            @apply w-2 h-2 my-0 mx-[0.1875rem];
            button {
                @apply w-2 h-2 p-0 rounded-full bg-white;
                &:before {
                    @apply hidden;
                }
            }
            &.slick-active {
                button {
                    @apply bg-blue-1;
                }
            }
        }
    }
    &:has(.slick-current:last-child) {
        .slick-dots {
            li:last-child {
                button {
                    @apply bg-blue-1;
                }
            }
        }
    }
}
div.pb-text-root {
    text-align: inherit;
}
