/* Tooltip Styles */

@layer components {
  html {
    [class *= "tooltip-wrapper"], .tooltip {
      @apply inline-flex;
    }
    [class *= "tooltip-title"], .tooltip-title {
      @apply text-gold-1 font-primary font-semibold text-xs leading-[1.125rem] inline-flex flex-wrap gap-1.5;
      &:hover {
        svg {
          path {
            @apply fill-gold-1;
          }
        }
      }
    }
    .tooltip-content {
      max-width: calc(100vw - 6.25rem);
      @apply w-[13.875rem] p-2.5 bg-grey-6 text-primary text-xs leading-[1.125rem] font-primary font-semibold rounded-none md:max-w-none;
      &[class *= "Tooltip_top"] {
        top: calc(-50% - 0.375rem);
        &:before {
          @apply border-t-grey-6;
        }
      }
      &[class *= "Tooltip_bottom"] {
        --tw-translate-y: 0;
        top: calc(100% + 0.9375rem);
        &:before {
          @apply border-b-grey-6;
        }
      }
      &[class *= "Tooltip_left"] {
        --tw-translate-x: -50%;
        --tw-translate-y: 0;
        top: calc(100% + 0.9375rem);
        @apply left-1/2 md:left-auto md:top-1/2;
        @screen md {
          --tw-translate-x: 0px;
          --tw-translate-y: -50%;
          right: calc(100% + 0.8125rem);
        }
        &:before {
          --tw-translate-y: 0;
          @apply border-b-grey-6 border-l-transparent left-1/2 -top-4 md:border-b-transparent md:border-l-grey-6 md:top-1/2 md:left-auto md:-right-[0.9375rem];
          @screen md {
            --tw-translate-x: 0px;
            --tw-translate-y: -50%;
          }
        }
      }
      &[class *= "Tooltip_right"] {
        --tw-translate-x: -50%;
        --tw-translate-y: -100%;
        top: calc(-50% - 0.375rem);
        @apply left-1/2 md:right-auto md:top-1/2;
        @screen md {
          --tw-translate-x: 0px;
          --tw-translate-y: -50%;
          left: calc(100% + 0.8125rem);
        }
        &:before {
          --tw-translate-y: 0;
          @apply border-r-transparent border-t-grey-6 left-1/2 -bottom-4 top-auto md:border-t-transparent md:border-r-grey-6 md:-left-1.5 md:top-1/2 md:bottom-auto;
          @screen md {
            --tw-translate-y: -50%;
          }
        } 
      }
    }
  }
}