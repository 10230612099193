.root {
    @apply relative;
}

.list {
    @apply flex gap-x-6;
}

.item {
    @apply relative flex items-center outline-none;

    &:focus,
    &:active {
        @apply outline-none;
    }
    :global {
        .btn-link {
            @apply text-white no-underline text-xs leading-[1.125rem] pb-[0.0625rem] font-bold relative;
            &:after {
                @apply lg:content-[''] lg:absolute lg:bottom-0 lg:inset-x-0 lg:border-t lg:border-solid lg:border-transparent;
            }
            span[aria-hidden] {
                @apply lg:mr-2;
            }
            &:hover, &.active {
                @apply lg:text-gold-2;
                &:after {
                    @apply lg:border-gold-2;
                }
            }
            span[class *= "-label"] {
                @apply hidden lg:inline-block;
            }
        }
    }
}

.contact-menu {
    @apply lg:hidden;
    :global {
        .header-contact-us-links {
            li {
                &:not(.phone-link) {
                    @apply hidden #{!important};
                    &:not(.mobile-only) {
                        @apply lg:block #{!important};
                    }
                }
                &.phone-link {
                    > a {
                        @apply text-white hover:text-white;
                        > span {
                            @apply hidden #{!important};
                            &:not(.mobile-only) {
                                @apply lg:inline-block #{!important};
                            }
                        }
                    }
                }
            }
        }
    }
}

.search-menu {
    @apply order-first lg:order-none;
    svg {
        @apply w-4 h-4 lg:w-3.5 lg:h-3.5;
    }
    :global {
        .btn-link {
            &:after {
                @apply lg:bottom-0 lg:inset-x-0;
            }
        }
    }
}

.user-menu {
    @apply hidden lg:flex;
}

.cart-menu {
    .cart-count {
        @apply absolute -top-[0.5625rem] -right-1.5 bg-red rounded-full px-[0.222rem] text-[0.6875rem] leading-[0.875rem] font-semibold tracking-[0.03125rem] text-white uppercase lg:ml-1 lg:text-xs lg:font-bold lg:leading-[1.125rem] lg:px-0 lg:bg-transparent lg:static lg:rounded-none;
        &:before {
            @apply lg:content-['('];
        }
        &:after {
            @apply lg:content-[')'];
        }
    }
    button:hover {
        .cart-count {
            @apply text-gold-2;
        }
    }
    :global {
        .cart-count {
            &:not(.has-count) {
                @apply hidden lg:inline-block;
            }
        }
    }
}

.bag-btn {
    @apply relative;
}
