/*
  Custom components for jsx elements
  -----------------------------------
  * Compose component classes from several utilities.
 */

/* EXAMPLE:
@layer components {
  .btn-big {
    @apply p-10 border border-5 border-p-1;
  }
}
*/

@layer components {
}
/* Tabs */
.tabs {
    @apply overflow-hidden relative;
}
.tab-content {
    @apply overflow-hidden max-h-0;
}
.tab-content-inner {
    @apply px-4 pb-5;
}
.tab-radio {
    @apply w-full absolute cursor-pointer opacity-0 h-10;
    z-index: 1;
}
.tab-header {
    @apply flex justify-between items-center py-2 cursor-pointer;
}
.tab-title {
    @apply text-base uppercase font-semibold;
}
.tab-header .arrow {
    @apply p-1 mr-2 border-secondary border-r-2 border-b-2 transform rotate-45 duration-200;
}
.tab-radio:checked + .tab-header .arrow {
    @apply -rotate-135;
}
.tab-radio:checked ~ .tab-content {
    @apply max-h-[1000vh];
}
/* Rich content Slider image URL fix for touch devices */
[class*='slick-slider'] [class*='productimage-block'] {
    span {
        span {
            @apply relative z-1;
        }
    }
}

.mobile-only {
    @apply !block md:!hidden;
}
.desktop-only {
    @apply !hidden md:!block;
}

/* Quantity Input Box Styles */
label[for='input-quantity'] {
    @apply text-[0rem] font-primary font-bold tracking-[0.03125rem] leading-[0.875rem] uppercase text-grey-1;
    &:before {
        content: 'Enter Qty';
        @apply text-xs leading-[0.875rem];
    }
}
div.pdp-qty {
    @apply border border-solid border-blue-1 rounded-none bg-white mt-2 w-[5.625rem] h-[2.8125rem] px-3.5 py-0 inline-flex items-center justify-between;
    label {
        input[type='number'] {
            @apply mx-0 h-full w-full text-grey-1 opacity-50 font-primary font-bold text-base leading-[2.8125rem] outline-none;
        }
    }
    button {
        @apply hidden;
    }
}

/* Breadcrumbs Styles */
.breadcrumbs {
    @apply font-primary font-medium text-sm leading-6 md:leading-8 text-blue-1 inline-flex flex-wrap gap-x-2 capitalize;
    > a {
        @apply no-underline text-blue-1 hover:text-hover;
    }
    > span {
        @apply font-extrabold;
    }
}

/* Pagination Styles */
div[class*='Pagination_page-navigation'] {
    a[class*='Pagination_nav-button'] {
        @apply text-xs leading-[1.125rem] font-primary font-medium pb-1.5 md:ml-4;
        &[class*='Pagination_selected'] {
            @apply tracking-[0.03125rem] font-bold;
        }
        svg {
            path {
                @apply fill-black;
            }
        }
    }
    span[class*='Pagination_nav-button'] {
        @apply text-xs leading-[1.125rem] font-primary font-medium pb-1.5 md:-ml-2 md:mr-0;
    }
    button[class*='Pagination_nav-button'] {
        &[class*='Pagination_disabled'] {
            @apply opacity-100 pb-1.5;
            svg {
                path {
                    @apply fill-grey-3;
                }
            }
        }
    }
    div[class*='Pagination_page-navigation-mobile'] {
        @apply block;
        div[class*='Pagination_page-navigation-buttons-mobile'] {
            @apply flex md:hidden;
            + div {
                @apply mt-[1.375rem] md:mt-3;
            }
            button[class*='Pagination_nav-button'] {
                @apply border-0 p-0 min-w-[1.25rem] text-center rounded-none mx-4;
                &:before,
                > span {
                    @apply hidden;
                }
                &:after {
                    -webkit-mask-image: url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.85311 8.85463C4.89967 8.80819 4.93661 8.75301 4.96182 8.69226C4.98703 8.63152 5 8.5664 5 8.50063C5 8.43486 4.98703 8.36974 4.96182 8.309C4.93661 8.24825 4.89967 8.19308 4.85311 8.14663L1.20611 4.50063L4.85311 0.854632C4.94699 0.760745 4.99974 0.633407 4.99974 0.500632C4.99974 0.367856 4.94699 0.240518 4.85311 0.146632C4.75922 0.052745 4.63188 4.38976e-09 4.49911 5.9731e-09C4.36633 7.55643e-09 4.23899 0.052745 4.14511 0.146632L0.145106 4.14663C0.0985432 4.19308 0.0616003 4.24825 0.0363937 4.309C0.0111871 4.36974 -0.00178713 4.43486 -0.00178713 4.50063C-0.00178713 4.5664 0.0111871 4.63152 0.0363937 4.69227C0.0616003 4.75301 0.0985432 4.80819 0.145106 4.85463L4.14511 8.85463C4.19155 8.90119 4.24673 8.93814 4.30747 8.96334C4.36822 8.98855 4.43334 9.00152 4.49911 9.00152C4.56487 9.00152 4.62999 8.98855 4.69074 8.96334C4.75149 8.93814 4.80666 8.90119 4.85311 8.85463Z" fill="currentColor"/></svg>');
                    mask-image: url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.85311 8.85463C4.89967 8.80819 4.93661 8.75301 4.96182 8.69226C4.98703 8.63152 5 8.5664 5 8.50063C5 8.43486 4.98703 8.36974 4.96182 8.309C4.93661 8.24825 4.89967 8.19308 4.85311 8.14663L1.20611 4.50063L4.85311 0.854632C4.94699 0.760745 4.99974 0.633407 4.99974 0.500632C4.99974 0.367856 4.94699 0.240518 4.85311 0.146632C4.75922 0.052745 4.63188 4.38976e-09 4.49911 5.9731e-09C4.36633 7.55643e-09 4.23899 0.052745 4.14511 0.146632L0.145106 4.14663C0.0985432 4.19308 0.0616003 4.24825 0.0363937 4.309C0.0111871 4.36974 -0.00178713 4.43486 -0.00178713 4.50063C-0.00178713 4.5664 0.0111871 4.63152 0.0363937 4.69227C0.0616003 4.75301 0.0985432 4.80819 0.145106 4.85463L4.14511 8.85463C4.19155 8.90119 4.24673 8.93814 4.30747 8.96334C4.36822 8.98855 4.43334 9.00152 4.49911 9.00152C4.56487 9.00152 4.62999 8.98855 4.69074 8.96334C4.75149 8.93814 4.80666 8.90119 4.85311 8.85463Z" fill="currentColor"/></svg>');
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-position: center;
                    mask-position: center;
                    @apply content-[''] w-[0.3125rem] h-[0.5625rem] bg-black relative transform;
                }
                &[class*='Pagination_disabled'] {
                    @apply pb-0;
                    &:after {
                        @apply bg-grey-3;
                    }
                }
            }
            > button[class*='Pagination_nav-button'] {
                &:last-child:after {
                    @apply rotate-180;
                }
            }
            > a {
                &:last-child {
                    button[class*='Pagination_nav-button']:after {
                        @apply rotate-180;
                    }
                }
            }
        }
        [class*='Pagination_current-page-text-mobile'] {
            @apply font-primary font-bold uppercase text-xs leading-[1.125rem] text-grey-1;
        }
    }
}
div[class*='PaginationToolbarStyle_pagination-toolbar'] {
    @apply items-center gap-4 md:gap-5;
    div[class*='PaginationToolbarStyle_dropdown-label'] {
        @apply border-grey-3 items-center;
    }
    div[class*='Dropdown_dropdown-options'][role='button'] {
        @apply border-grey-3 py-1 px-1.5 border-t-0 z-1;
    }
    .pagination-list {
        @apply rounded-none;
        > li {
            @apply m-0;
            .page-link {
                @apply block min-w-[1.25rem] pb-1.5 text-xs leading-[1.125rem] font-primary font-medium mx-3 md:ml-4 md:mr-[1.125rem] no-underline hover:no-underline;
            }
            .btn-link:not(.page-link) {
                @apply min-w-[1.25rem] block min-h-[1.5rem] pb-1.5 mx-3 md:ml-4 md:mr-[1.125rem] no-underline hover:no-underline;
                > span {
                    @apply hidden;
                }
                &:before {
                    -webkit-mask-image: url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.85311 8.85463C4.89967 8.80819 4.93661 8.75301 4.96182 8.69226C4.98703 8.63152 5 8.5664 5 8.50063C5 8.43486 4.98703 8.36974 4.96182 8.309C4.93661 8.24825 4.89967 8.19308 4.85311 8.14663L1.20611 4.50063L4.85311 0.854632C4.94699 0.760745 4.99974 0.633407 4.99974 0.500632C4.99974 0.367856 4.94699 0.240518 4.85311 0.146632C4.75922 0.052745 4.63188 4.38976e-09 4.49911 5.9731e-09C4.36633 7.55643e-09 4.23899 0.052745 4.14511 0.146632L0.145106 4.14663C0.0985432 4.19308 0.0616003 4.24825 0.0363937 4.309C0.0111871 4.36974 -0.00178713 4.43486 -0.00178713 4.50063C-0.00178713 4.5664 0.0111871 4.63152 0.0363937 4.69227C0.0616003 4.75301 0.0985432 4.80819 0.145106 4.85463L4.14511 8.85463C4.19155 8.90119 4.24673 8.93814 4.30747 8.96334C4.36822 8.98855 4.43334 9.00152 4.49911 9.00152C4.56487 9.00152 4.62999 8.98855 4.69074 8.96334C4.75149 8.93814 4.80666 8.90119 4.85311 8.85463Z" fill="currentColor"/></svg>');
                    mask-image: url('data:image/svg+xml,<svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.85311 8.85463C4.89967 8.80819 4.93661 8.75301 4.96182 8.69226C4.98703 8.63152 5 8.5664 5 8.50063C5 8.43486 4.98703 8.36974 4.96182 8.309C4.93661 8.24825 4.89967 8.19308 4.85311 8.14663L1.20611 4.50063L4.85311 0.854632C4.94699 0.760745 4.99974 0.633407 4.99974 0.500632C4.99974 0.367856 4.94699 0.240518 4.85311 0.146632C4.75922 0.052745 4.63188 4.38976e-09 4.49911 5.9731e-09C4.36633 7.55643e-09 4.23899 0.052745 4.14511 0.146632L0.145106 4.14663C0.0985432 4.19308 0.0616003 4.24825 0.0363937 4.309C0.0111871 4.36974 -0.00178713 4.43486 -0.00178713 4.50063C-0.00178713 4.5664 0.0111871 4.63152 0.0363937 4.69227C0.0616003 4.75301 0.0985432 4.80819 0.145106 4.85463L4.14511 8.85463C4.19155 8.90119 4.24673 8.93814 4.30747 8.96334C4.36822 8.98855 4.43334 9.00152 4.49911 9.00152C4.56487 9.00152 4.62999 8.98855 4.69074 8.96334C4.75149 8.93814 4.80666 8.90119 4.85311 8.85463Z" fill="currentColor"/></svg>');
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-position: center;
                    mask-position: center;
                    @apply content-[''] w-[0.3125rem] h-[0.5625rem] bg-black relative transform block mx-auto;
                }
            }
            &:last-child {
                .btn-link:not(.page-link) {
                    &:before {
                        @apply rotate-180;
                    }
                }
            }
            &.active {
                .page-link {
                    @apply font-bold tracking-[0.03125rem] border-b border-solid border-black;
                }
            }
        }
    }
}

/* Tabs Styles */
ul.order-details-nav {
    @apply flex flex-wrap md:flex-nowrap p-0;
}
li[class*='Tabs_title'] {
    @apply flex-grow p-0 md:max-w-[17.25rem] bg-white border-0;
    &[class*='Tabs_active'] {
        @apply bg-grey-4 mr-0 pb-0;
        > .btn-link {
            @apply text-blue-1 no-underline hover:text-blue-1;
        }
    }
    > .btn-link {
        @apply block w-full no-underline text-xs leading-[0.875rem] p-[0.9375rem] truncate uppercase tracking-[0.03125rem] font-bold text-blue-4 hover:text-blue-4;
    }
}
div[class*='Tabs_content'] {
    @apply border-grey-4 bg-white mt-0 p-5 lg:p-[1.875rem];
}
.dark-tabs {
    @apply bg-blue-1;
    li[class*='Tabs_title'] {
        @apply bg-transparent opacity-100;
        &[class*='Tabs_active'] {
            @apply bg-transparent opacity-100 md:max-w-[17.6875rem];
            > .btn-link {
                @apply text-white hover:text-white;
            }
        }
        > .btn-link {
            @apply text-blue-2 p-4 hover:text-blue-2;
        }
    }
    div[class*='Tabs_content'] {
        @apply border-0;
    }
}

/* form logo styles */
div.form-logo svg {
    @apply w-[12.5rem] h-[2.625rem];
}

/* removed the custom dropdown arrows */
div[class*='RegionField_dropdown'],
div[class*='CountryField_dropdown'] {
    &:after {
        @apply hidden;
    }
}

/* View Chart Banner animation styles */
.chart-price-list {
    animation: ticker 10s linear infinite;
    @apply lg:animate-none;
    &:hover {
        animation-play-state: paused;
    }
}
@keyframes ticker {
    0% {
        margin-top: 2.0625rem;
    }

    100% {
        margin-top: -8.25rem;
    }
}

/* Account Dropdown Overlay style */
.show-account-dropdown {
    &,
    body {
        @apply overflow-hidden h-full;
    }
    body {
        @apply relative;
    }
    main.fit {
        &:before {
            @apply content-[''] bg-black bg-opacity-[0.37] absolute inset-x-0 top-0 z-10 h-screen;
        }
    }
}

/* Auth Modal Style */
div[class*='Layout_auth-modal'] {
    > button {
        @apply px-2.5 flex-grow;
    }
    & [class*='Modal_modal-content-body'] {
        @apply flex-auto relative pt-[3.125rem] sm:mx-[0.625rem] mx-0 md:mx-3 p-0;
    }
    & [class*='Modal_close-panel'] {
        @apply -right-[1rem];
    }
}

button[data-variant='secondary'] {
    &.sell-button {
        @apply px-[0.3125rem] py-[.53rem];
    }
}
/* PDP Zoom Style */
.zoom-open {
    [class*='pdp-gallery-wrapper'] .pdp-gallery {
        @apply md:static;
    }
}

/* PLP Subcategory hidden style */
div[class*='PlpSubcategories_plpSubcategory'] {
    @apply hidden;
}

/* No Result Link style */
.no-result-wrapper {
    .text-link {
        @apply align-baseline ml-1;
    }
}

/* PDP Related Products SSR View Styles */
body.loading .recent-related-container .slick-slide {
    @apply xl:min-w-[17.5625rem];
}

/* PDP SKU Visible Style for Internal Store */
.usgb_admin .product-topinfo .product-sku {
    @apply block;
}