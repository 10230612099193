:root {
  /* define custom variables for the entire project */
  --another: rgb(179, 0, 255);

  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;
  --secondary-2: #333;

  --selection: var(--cyan);

  --text-base: #001F3D;
  --text-primary: #000000;
  --text-secondary: white;

  --hover: #5CAAD6;
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);

  --cyan: #22b8cf;
  --green: #37b679;
  --red: #E61B1B;
  --pink: #e64980;
  --purple: #f81ce5;

  --blue: #0070f3;

  --violet-light: #7048e8;
  --violet: #5f3dc4;
  --dropdown-blue: #5E98F8;

  --accents-0: #f8f9fa;
  --accents-1: #f1f3f5;
  --accents-2: #e9ecef;
  --accents-3: #dee2e6;
  --accents-4: #ced4da;
  --accents-5: #ABA9A9;
  --accents-6: #868e96;
  --accents-7: #4F4F4F;
  --accents-8: #343a40;
  --accents-9: #212529;
  --font-sans: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif;

  --border-color: #ABA9A9;

  /* true vars */
  --base-font: 16px;

  --p1: #000000;
  --p2: #242424;
  --p3: #9B9B9B;
  --p4: #D8D8D8;

  --s1: #681445;
  --s2: #FF7474;
  --s3: #F0CECE;
  --s4: #D62727;

  --t1: #FFFFFF;
  --t2: #BFBFBF;
  --t3: #979797;
  --t4: #001F3D;

  --q1: #FFFFFF;
  --q2: #F7F7F7;
  --q3: #F3F3F3;
  --q4: #D8D8D8;

  --cms1: #FF6161;
  --cms2: #1CB2D2;

  --alert-success: #CCED7C;
  --alert-danger: #E61B1B;
  --alert-warning: #FFD705;

  /* custom height */
  --h-100: 28rem;
  --h-104: 32rem;
  --h-108: 36rem;
  --h-112: 40rem;
  --h-116: 44rem;
  --h-120: 48rem;
  --h-screen-2: calc(100vh / 2);
  --h-screen-3: calc(100vh / 3);
  --h-screen-4: calc(150vh);
  --h-screen-5: calc(200vh);

  /* custom colors */
  --blue-1: #001F3D;
  --blue-2: #5CAAD6;
  --blue-3: #A5D3F2;
  --blue-4: #2174A3;
  --blue-5: #275582;
  --blue-6: #285582;
  --gold-1: #6D4B0F;
  --gold-2: #F4E08E;
  --gold-3: #EFE6C1;
  --green-1: #234F07;
  --green-2: #266041;
  --green-3: #CCED7C;
  --green-4: #E3EADD;
  --green-5: #AEDD47;
  --yellow: #FFD705;
  --grey-1: #4D4D4D;
  --grey-2: #7D7D7D;
  --grey-3: #898989;
  --grey-4: #D9D9D9;
  --grey-5: #ECECEC;
  --grey-6: #F2F2F2;
  --grey-7: #BDBDBD;
  --grey-8: #E0E0E0;
  --grey-9: #828282;
  --grey-10: #999999;
  --grey-11: #666666;
  --grey-12: #cccccc;
  --grey-13: #f6f6f6;
}

[data-theme='dark'] {
  --primary: #000000;
  --primary-2: #111;
  --secondary: #ffffff;
  --secondary-2: #f1f3f5;
  --hover: #5CAAD6;
  --hover-1: rgba(255, 255, 255, 0.15);
  --hover-2: rgba(255, 255, 255, 0.25);
  --selection: var(--purple);

  --text-base: white;
  --text-primary: white;
  --text-secondary: black;

  --accents-0: #212529;
  --accents-1: #343a40;
  --accents-2: #495057;
  --accents-3: #868e96;
  --accents-4: #adb5bd;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;

  --p1: #fff;
  --p2: #EAB512;
  --p3: #FFA800;
  --p4: #D57004;

  --s1: #f3a125;
  --s2: #f27a10;
  --s3: #ff5300;
  --s4: #c94010;

  --t1: #000000;
  --t2: #f2f2f0;
  --t3: #e4e4e1;
  --t4: #ffffff;

  --q1: #000000;
  --q2: #111111;
  --q3: #222222;
  --q4: #D8D8D8;

  --gs1: #414040;
  --gs2: #838282;
  --gs3: #CDCACA;
}
