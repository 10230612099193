/* RSD Styles */
.rsd-slider-bg {
    background:#f6f6f6;
    position:relative;

    .quote-bg {
        position:absolute;
        left:20%;
        top:30px;
    }

}
.rsd_our_process_tabs{
    ul.order-details-nav {
        background:#f6f6f6;
        margin:0;
        padding:10px !important;
    }
    li[class*='Tabs_title'] {
        margin-bottom:0 !important;
        background:none;
        position:relative;
    }
    li[class*='Tabs_title']:after {
        margin:auto;
        content:url('data:image/svg+xml,<svg width="30" height="30" viewBox="0 0 50 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M29.5133 24.2041L21.7266 16.1626C21.2733 15.7235 20.5799 15.7235 20.1533 16.1626C19.7266 16.6018 19.7266 17.3428 20.1533 17.7819L27.1666 25L20.1533 32.218C19.7266 32.6846 19.7266 33.3982 20.1533 33.8373C20.5799 34.2764 21.2733 34.2764 21.7266 33.8373L29.5133 25.8233C29.9399 25.3567 29.9399 24.6432 29.5133 24.2041Z" fill="%23001F3D" stroke="%23001F3D" stroke-width="0.5"/></svg>');
        position:absolute;
        top:0;
        bottom:0;
        right:0;
    }
    li[class*='Tabs_title'][class*='Tabs_active'] {
        background:#EFE6C1;
    }
    li[class*='Tabs_title'][class*='Tabs_active']:after {
        content:'';
    }
    li[class*='Tabs_title']:last-child:after {
        content:''
    }
}


@media screen and (max-width:767px){
    .rsd-side-navigation  {
        a {
            position:relative;
        }
        a.active:after {
            margin:auto;
            content:url('data:image/svg+xml,<svg width="30" height="30" viewBox="0 0 50 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M29.5133 24.2041L21.7266 16.1626C21.2733 15.7235 20.5799 15.7235 20.1533 16.1626C19.7266 16.6018 19.7266 17.3428 20.1533 17.7819L27.1666 25L20.1533 32.218C19.7266 32.6846 19.7266 33.3982 20.1533 33.8373C20.5799 34.2764 21.2733 34.2764 21.7266 33.8373L29.5133 25.8233C29.9399 25.3567 29.9399 24.6432 29.5133 24.2041Z" fill="%236D4B0F" stroke="%236D4B0F" stroke-width="0.5"/></svg>');
            position:absolute;
            top:2px;
            bottom:0;
            right:-21px;
        }

        a:last-child:after {
            content:''
        }
    }
    .rsd-slider-bg {
        background:none;

        button.slick-arrow.slick-next, button.slick-arrow.slick-prev {
            display:none !important;
        }
        .rsd-slide.wrapper p {
            font-size:14px !important;
            color:#5f5a5a !important;
            position:relative;
            z-index:2;
        }
        .slick-slide {
            background:#f6f6f6;
            padding:20px;
            margin:10px;
            height:auto;
            display:flex !important;
            align-items:center !important;
        }
        .quote-bg {
            display:none;
        }

    }
    .rsd-slider-bg .slick-slide:before {
        content: url('data:image/svg+xml,<svg width="103" height="76" viewBox="0 0 103 76" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M63.3633 0.0507812C62.1784 7.52474 60.8112 15.2721 59.2617 23.293C57.8034 31.3138 57.0742 38.9245 57.0742 46.125C57.0742 54.0547 58.7148 60.1159 61.9961 64.3086C65.3685 68.5013 69.8802 71.3724 75.5312 72.9219C81.1823 74.4714 87.3802 75.2461 94.125 75.2461C94.6719 75.2461 95.2188 75.2461 95.7656 75.2461C96.3125 75.2461 96.8594 75.2461 97.4062 75.2461V54.6016C93.3047 53.8724 90.6615 52.2773 89.4766 49.8164C88.3828 47.4466 87.8359 44.2565 87.8359 40.2461C87.8359 39.1523 87.8359 37.7396 87.8359 36.0078C87.9271 34.3672 87.9727 32.8177 87.9727 31.3594C88.0638 29.901 88.1549 28.8984 88.2461 28.3516L99.0469 32.3164C99.2292 30.4023 99.457 27.987 99.7305 25.0703C100.095 22.2448 100.46 19.2826 100.824 16.1836C101.189 13.0846 101.508 10.0768 101.781 7.16016C102.146 4.33464 102.419 1.96484 102.602 0.0507812H63.3633ZM6.48828 0.0507812C5.30339 7.52474 3.9362 15.2721 2.38672 23.293C0.928385 31.3138 0.199219 38.9245 0.199219 46.125C0.199219 54.0547 1.83984 60.1159 5.12109 64.3086C8.49349 68.5013 13.0052 71.3724 18.6562 72.9219C24.3073 74.4714 30.5052 75.2461 37.25 75.2461C37.7969 75.2461 38.3438 75.2461 38.8906 75.2461C39.4375 75.2461 39.9844 75.2461 40.5312 75.2461V54.6016C36.4297 53.8724 33.7865 52.2773 32.6016 49.8164C31.5078 47.4466 30.9609 44.2565 30.9609 40.2461C30.9609 39.1523 30.9609 37.7396 30.9609 36.0078C31.0521 34.3672 31.0977 32.8177 31.0977 31.3594C31.1888 29.901 31.2799 28.8984 31.3711 28.3516L42.1719 32.3164C42.3542 30.4023 42.582 27.987 42.8555 25.0703C43.2201 22.2448 43.5846 19.2826 43.9492 16.1836C44.3138 13.0846 44.6328 10.0768 44.9062 7.16016C45.2708 4.33464 45.5443 1.96484 45.7266 0.0507812H6.48828Z" fill="%23ECECEC"/></svg>');
        position:Absolute;
        top:20px;
    }
}
@media screen and (max-width:850px) {
    .rsd_our_process_tabs {
        ul.order-details-nav {
            flex-wrap: initial;
            padding-left:0px !important;
        }
        li[class*='Tabs_title']:after {
            right:-5px;
            top:3px;
            content: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 50 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M29.5133 24.2041L21.7266 16.1626C21.2733 15.7235 20.5799 15.7235 20.1533 16.1626C19.7266 16.6018 19.7266 17.3428 20.1533 17.7819L27.1666 25L20.1533 32.218C19.7266 32.6846 19.7266 33.3982 20.1533 33.8373C20.5799 34.2764 21.2733 34.2764 21.7266 33.8373L29.5133 25.8233C29.9399 25.3567 29.9399 24.6432 29.5133 24.2041Z" fill="%23001F3D" stroke="%23001F3D" stroke-width="0.5"/></svg>')
        }
    }
}
/* Hubspot form */
#rsd_hubspotForm {
    form input[type='submit'] {
        width:100%;
    }
    form input {
        border:1px solid #ECECEC;
    }
    form .hs-form-field {
        margin-top:15px
    }
    .legal-consent-container {
        margin:20px 0;
        font-size:12px;
        line-height:18px;
        color:#4D4D4D;
        font-weight:100;
    }
}
/* table */
.rsd_table {
    table {
        border-collapse: collapse;
        width: 100%;
    }
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
}
@media screen and (max-width:767px){
    .rsd_table {
        th.first-header {
            display:none;
            }
            th {
                display:inline-flex;
                width:50%;
                flex-wrap:nowrap
            }
            td {
                display:inline-flex;
                width:50%;
                align-items:center;
                border-top:0px;
                border-bottom:0px;
                vertical-align: top;
            }
            td.title{
                width:100%;
                min-height:auto
            }
    }
}
@media screen and (max-width:500px){
    img.m-fit {
        width:100%;
        max-height:200px;
        object-fit:cover;
        object-position:0px -70px;
    }
}