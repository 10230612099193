/* Toast Styles */

@layer components {
  html {
    & [class *= "toast-container"] {
      @apply rounded-[3.125rem] w-full my-2 pt-[0.5625rem] pr-4 pb-2 pl-2.5 md:pl-[1.6875rem] max-w-[90rem] mx-auto;
      box-shadow: 0 0.0625rem 0.625rem 0 rgba(0,0,0,0.10);

      &.-translate-y-full\+ {
        @apply opacity-0;
      }

      &.bg-alert-success {
        @apply bg-[#E3EADD];
        & [class *= "toast-content-inner"] {
          & > svg {
            @apply text-new-green-1;
          }
          & > [class *= "toast-text"] {
            & .toast-title-text {
              @apply text-new-green-1;
            }
          }
        }
      }

      &.bg-alert-danger {
        background-color: rgba(214, 50, 69, 0.08);
        & [class *= "toast-content-inner"] {
          & > svg {
            @apply text-red;
          }
          & > [class *= "toast-text"] {
            & .toast-title-text {
              @apply text-sm leading-[1.6875rem] uppercase text-red;
            }
          }
        }
      }
      &.bg-alert-warning {
        @apply bg-gold-2 md:bg-[#FDF9E3];
        & [class *= "toast-content-inner"] {
          & > svg {
            @apply text-gold-1;
          }
          & > [class *= "toast-text"] {
            & .toast-title-text {
              @apply text-gold-1;
            }
          }
        }
      }

      & > [class *= "toast-content"] {
        @apply flex flex-1 items-center justify-between;
      }
    }

    & [class *= "toast-content-inner"] {
      @apply flex flex-1 w-full items-center justify-between text-xs leading-[1.125rem] font-medium -tracking-[0.015625rem] text-primary gap-x-2 md:gap-x-6 lg:gap-x-10;
      max-width: calc(100% - 1.875rem);
      & > :not([hidden])~:not([hidden]) {
        @apply mx-0;
      }
      & > [class *= "toast-text"] {
        @apply flex-1 flex-col text-black text-xs leading-[1.125rem] font-medium -tracking-[0.015625rem] text-primary m-0;
        max-width: calc(100% - 1.25rem);

        & .toast-title-text {
          @apply text-lg leading-[1.6875rem] font-primary font-extrabold tracking-normal;
        }

        & a, & [class *= "-link"], & .link {
          @apply text-current underline hover:text-current hover:no-underline;
        }
      }
      & > svg {
        @apply w-[1.125rem] h-[1.125rem] md:w-[1.875rem] md:h-[1.875rem];
        & path {
          @apply fill-current;
        }
      }
      & + svg {
        @apply w-[0.6875rem] h-[0.6875rem] md:w-2.5 md:h-2.5;
        & path {
          @apply fill-black;
        }
      }
    }
  }
}