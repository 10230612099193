    /* blog styles */
    .amblog-post-container {
        margin-bottom:30px;
    
        span.cats-container {
            display:block;
            overflow:hidden;
        }
        span.cats-container span {
            float:left;
        }
        span.author-container {
            display:block;
            overflow:hidden;
            margin-top:10px;
            img {
                float:left;
                margin-right:5px;
            }
    
            a {
                margin-left:5px;
            }
        }
    
        span.block.date-views-container {
            margin:10px 0;
            color:#999;
        }
        span.blog-views {
            margin-left:10px;
        }
        .amblog-content {
            border:1px solid #ddd;
            padding:20px;
            h4 {
                font-size:24px;
                max-width:500px;
                font-weight:500;
            }
            figure {
                overflow:hidden;
                display:inline-block;
                img {
                    max-width:250px;
                }
            }
            .post-tags {
                margin:5px 0;
                ul {
                    li {
                        display:inline-block;
                        a {
                            font-size:12px;
                            padding:5px;
                        }
                    }
                }
            }
    
            .blog-content-top-container {
                overflow:hidden;
                display:flex;
                .blog-post-content-container {
                    width:80%;
                    text-align:left;
                    padding: 0 20px 0;
                    display:inline-block;
                    h1 {
                        font-size:2.0rem;
                        line-height:2.25rem;
                    }
                }
            }            
        }
        .amblog-footer {
            border:1px solid #ddd;
            border-top:0px;
            display:flex;
            justify-content:space-between;
            a {
                margin:0;
                padding:0;
            }
            .blog-cats-author-container {
                display:block !important;
                padding:30px;
                color:#999;
                a {
                    color:#275582;
                }
            }
            a.read_more {
                display:flex;
                align-items:center;
                border:none;
                border-left:1px solid #ddd;
                font-size:16px;
                color:#275582;
                text-transform: uppercase;
                font-weight:bold;
                padding:50px 30px;
            }
    
        }
    }
    
    @media screen and (max-width:667px){
        .amblog-post-container {
            .amblog-content {
                figure {
                    overflow:hidden;
                    display:inline-block;
                    img {
                        max-width:100%;
                    }
                }
                .blog-content-top-container {
                    overflow:hidden;
                    display:block;
                    .blog-post-content-container {
                        width:100%;
                        text-align:left;
                        padding: 0 20px 0;
                        display:inline-block;
                        margin-top:20px;
                    }
                }
            }
            .amblog-footer {
                display:block;
                a.read_more {
                    border:none;
                    border-top:1px solid #ddd;
                    padding:10px;
                    text-align:Center;
                    display:block;
                }
    
            }
            span.author-container {
                display:inline-block;
            }
            span.cats-container {
                display:inline-block;
            }
        }
    }
    /* blog form */
    .blog-main-content {
        max-width:815px;
        .free-investor-kit._hubspot-usgb {
            border:1px solid #ccc;
            background:#f9f9f9;
            img {
                float:left;
                margin-right:10px;
            }
            .row {
                margin:10px;
                .col-lg-12 {
                    margin:0;
                    padding:0;
                    p {
                        margin-top: 0.75rem;
                        font-size: 0.938rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        line-height: 1;
                        color:#9e854b;
                    }
                }
            }
            input {
                border:1px solid #ccc;
                width:97%;
                padding:5px;
                margin:5px;
            }
            .legal-consent-container {
                p {
                    font-size:12px;
                    text-align:Center;
                    margin:20px;
                }
            }
            .hs_submit.hs-submit input[type=submit]{
                background:#021f3c;
                color:#fff;
                font-weight:bold;
            }
        }
    
        ul.no-list.hs-error-msgs.inputs-list    {
            color:#ff0000;
            margin-left:10px;
        }
    
    }