/* Button styles */

@layer components {
  html {
    .btn-primary, .btn-secondary, .btn-primary-type-2 .btn-primary, .btn-secondary-type-2 .btn-primary,
    button[priority="high"], button[priority="normal"],
    button[data-variant='primary'],
    button[data-variant='secondary'],
    button[data-variant='slim'],
    button[data-variant='flat'],
    .btn-continue-shopping,
    button[variant='primary'],
    button[variant='secondary'] {
      @apply no-underline py-2.5 px-[1.875rem] rounded-[3.125rem] text-xs leading-[0.9375rem] font-primary font-bold tracking-[0.03125rem] outline-none focus:shadow-none transform transition-all duration-200 uppercase;
      > span {
        @apply flex justify-center;
      }
    }

    .btn-primary, 
    button[data-variant='primary'], 
    button[data-variant='slim'], 
    button[data-variant='flat'], 
    button[priority="high"],
    .btn-continue-shopping,
    button[variant='primary'] {
      @apply border-0 bg-blue-1 text-white;
      &.disabled, &[disabled] {
        @apply border-0 bg-grey-7 text-white opacity-100 pointer-events-none;
        &:active,
        &:focus, &:hover {
          @apply bg-grey-7 text-white;
        }
      }
      &:active,
      &:focus {
        @apply border-0 bg-blue-1 text-white;
      }
      &:hover {
        @apply border-0 bg-hover text-white transform transition-all duration-200;
      }     
    }

    .btn-primary-type-2 .btn-primary,
    .btn-primary-type-2 button[priority="high"],
    button[data-variant='flat'].btn-primary-type-2 {
      @apply border-0 text-t-4 bg-primary relative;
      &.disabled, &[disabled] {
        @apply border-0 bg-grey-2 text-secondary opacity-100 pointer-events-none;
        &:before {
          @apply hidden;
        }
        &:active,
        &:focus, &:hover {
          @apply bg-grey-2 text-secondary;
          text-shadow: none;
        }
      }
      &:before {
        @apply content-[''] absolute inset-0 border-[0.1875rem] border-solid border-hover rounded-[3.125rem] opacity-0 transform transition-opacity duration-200;
      }
      &:active,
      &:focus {
        @apply border-0 text-t-4 bg-primary;
      }
      &:hover {
        @apply border-0 text-t-4 bg-primary transform transition-all duration-200;
        text-shadow: 0.025rem 0 0 currentColor;
        &:before {
          @apply opacity-100;
        }
      }      
    }

    .btn-secondary, 
    button[data-variant='secondary'],
    button[priority="normal"],
    button[variant='secondary'] {
      @apply border border-solid border-gold-1 bg-transparent text-gold-1 relative py-[0.5625rem] px-[1.8125rem] transform transition-all duration-200;
      &.disabled, &[disabled] {
        @apply bg-transparent border border-solid border-grey-2 text-grey-9 opacity-100 pointer-events-none;
        &:before {
          @apply hidden;
        }
        &:active,
        &:focus, &:hover {
          @apply border-grey-2 text-grey-9;
          text-shadow: none;
        }
      }
      &:before {
        @apply content-[''] absolute inset-0 border-[0.1875rem] border-solid border-gold-1 rounded-[3.125rem] opacity-0 transform transition-opacity duration-200;
      }
      &:active,
      &:focus {
        @apply border border-solid border-gold-1 bg-transparent text-gold-1;
      }
      &:hover {
        @apply border border-solid border-gold-1 text-gold-1 bg-transparent transform transition-all duration-200;
        text-shadow: 0.025rem 0 0 currentColor;
        &:before {
          @apply opacity-100;
        }
      }
    }

    .btn-secondary-type-2 .btn-primary,
    .btn-secondary-type-2 button[priority="high"],
    button[data-variant='flat'].btn-secondary-type-2 {
      @apply border border-solid border-primary bg-transparent text-secondary relative py-[0.5625rem] px-[1.8125rem] transform transition-all duration-200;
      &.disabled, &[disabled] {
        @apply bg-transparent border border-solid border-grey-8 text-grey-7 opacity-100 pointer-events-none;
        &:before {
          @apply hidden;
        }
        &:active,
        &:focus, &:hover {
          @apply border border-solid border-grey-8 text-grey-7 bg-transparent;
          text-shadow: none;
        }
      }
      &:before {
        @apply content-[''] absolute inset-0 border-[0.1875rem] border-solid border-primary rounded-[3.125rem] opacity-0 transform transition-opacity duration-200;
      }
      &:active,
      &:focus {
        @apply border border-solid border-primary bg-transparent text-secondary;
      }
      &:hover {
        @apply border border-solid border-primary text-secondary bg-transparent transform transition-all duration-200;
        text-shadow: 0.025rem 0 0 currentColor;
        &:before {
          @apply opacity-100;
        }
      }      
    }

    .btn-primary-type-plp .btn-primary,
    .btn-primary-type-plp button[priority="high"],
    button[data-variant='flat'].btn-primary-type-plp {
      @apply bg-blue-4;
      &.disabled, &[disabled] {
        @apply bg-grey-4 text-grey-2;
        &:active,
        &:focus, &:hover {
          @apply bg-grey-4 text-grey-2;
          text-shadow: none;
        }
      }
      &:active,
      &:focus {
        @apply bg-blue-4;
      }
      &:hover {
        @apply bg-blue-1;
        text-shadow: 0.025rem 0 0 currentColor;
      }  
    }

    &[data-theme="dark"] {
      .btn-primary-type-2 .btn-primary,
      button[data-variant='flat'].btn-primary-type-2 {
        @apply text-blue-1 bg-secondary;
        &.disabled, &[disabled] {
          @apply bg-grey-2 text-white;
          &:active,
          &:focus, &:hover {
            @apply bg-grey-2 text-white;
          }
        }
        &:active,
        &:focus {
          @apply text-blue-1 bg-secondary;
        }
        &:hover {
          @apply text-hover bg-secondary;
        }      
      }
      
      .btn-secondary-type-2 .btn-primary,
      button[data-variant='flat'].btn-secondary-type-2 {
        @apply border-secondary text-white;
        &.disabled, &[disabled] {
          @apply border-grey-8 text-grey-7;
          &:active,
          &:focus, &:hover {
            @apply border-grey-8 text-grey-7;
          }
        }
        &:before {
          @apply border-secondary;
        }
        &:active,
        &:focus {
          @apply border-secondary text-white;
        }
        &:hover {
          @apply border-secondary text-white;
        }      
      }
    }
  }

  button[priority="low"],
  button[variant='link'],
  .text-link {
    @apply relative inline-block align-top pt-0 px-0 pb-0.5 no-underline uppercase font-bold text-xs leading-[1.125rem] tracking-[0.03125rem] text-blue-1 hover:text-hover focus:shadow-none;
    &:after {
      @apply content-[''] absolute bottom-0 inset-x-0 z-0 border-b border-solid border-current;
    }
    &.disabled, &[disabled] {
      @apply opacity-100 text-grey-7 pointer-events-none;
      &:after {
        @apply border-grey-4;
      }
    }
  }

  .btn-link-type-2 {
    button[priority="low"],
    button[variant='link'],
    .text-link {
      @apply text-white hover:text-white;
      &:hover {
        &:after {
          @apply border-b-[0.1875rem];
        }
      }
      &.disabled, &[disabled] {
        @apply text-grey-7;
      }
    }
  }

  [data-theme="dark"] {
    button[priority="low"],
    button[variant='link'],
    .text-link {
      @apply text-white hover:text-hover;
      &.disabled, &[disabled] {
        @apply text-grey-7;
      }
    }
    .btn-link-type-2 {
      button[priority="low"],
      button[variant='link'],
      .text-link {
        @apply text-white hover:text-white;
        &.disabled, &[disabled] {
          @apply text-grey-7;
        }
      }
    }
  }
}
