/* Common CMS Block Styles */
div[class*="row_contained"] {
    @apply max-w-[90rem] overflow-x-hidden mx-auto w-full;
}
.cart-trust-images {
    @apply flex gap-2.5 scale-[80%] md:scale-100 justify-center;
 }

 .cms-content {
    margin: 0 auto;
 }

.hidden-owl-carousel {
  @apply hidden;
}
.blog-cms {
    @apply pb-5;
    .amblog {
        &-element-block, &-latest-carousel {
            &.pagebuilder-slider.progress-bar-slider {
                @apply pr-0 pl-[1.5625rem] md:px-6 lg:px-8 xl:px-10 overflow-hidden;
                .slick-slider {
                    .slick-slide {
                        @apply md:mx-0;
                    }
                }
            }
        }
    }
}
/* Latest Blog Post Styles */
 .amblog {
    &-list {
        @apply flex md:grid md:gap-x-4 xl:gap-x-5 md:grid-cols-4 md:text-center;
        &.hidden-owl-carousel {
        @apply hidden;
        }
        .post-item-info {
        @apply flex flex-col;
        }
        .post-image {
        @apply aspect-square block;
        img {
            @apply object-cover w-full h-full;
        }
        }
    }
    &-ref {
        @apply font-bold;
        &:not(:last-child) {
            &:after {
                @apply content-[','];
            }
        }
    }
    &-element-block {
        @apply pl-[1.5625rem] pr-0 pt-0 md:pt-10 xl:pt-[3.125rem] md:px-6 lg:px-8 xl:px-10 overflow-hidden;
    }
    &-element-block, &-latest-carousel {
        &.owl-carousel.progress-bar-slider {
            @apply md:p-0;
        }
        .post-item-details {
        @apply grid grid-cols-1 gap-y-[1.125rem] md:gap-y-4 leading-6 mt-[1.125rem] pr-5 md:pr-0 md:mt-3.5;
        }
        .post-title, .amblog-headline {
        @apply font-primary font-extrabold text-grey-1 no-underline text-base leading-[1.6875rem] capitalize md:text-center;
        }
        .amblog-posted-in {
            @apply mr-0.5;
        }
        &.pagebuilder-slider.progress-bar-slider {
            @apply px-0;
            .slick-slider {
                .slick-track {
                    @apply flex md:gap-5;
                    &:before, &:after {
                        @apply hidden;
                    }
                    &:has(.slick-current:last-child:not(:first-child)) {
                        @apply -left-[1.5625rem] md:left-0;
                    }
                }
                .slick-slide {
                    @apply mx-2.5 md:mx-0;
                    &:first-child {
                    @apply ml-0;
                    }
                }
                .slick-prev, .slick-next {
                    top: calc(50% - 1.75rem);
                }
                .slick-prev {
                    @apply -left-[0.8125rem];
                }
                .slick-next {
                    @apply right-[0.8125rem];
                }
                .slick-dots {
                    @apply mt-7 mr-[1.5625rem] md:mr-auto;
                }
            }
        }
    }
    &-views {
    @apply hidden;
    }
    &-date {
    @apply text-blue-4 font-bold uppercase md:text-center text-xs leading-[0.875rem] tracking-[0.03125rem];
    }
    &-category {
    @apply  text-grey-1 md:text-center text-xs leading-[1.125rem] -tracking-[0.015625rem] mt-2.5 mb-0.5 md:my-0;
    }
    &-title {
    @apply text-black text-left text-lg leading-[1.6875rem] font-extrabold font-primary mb-3.5 pt-[1.9375rem] capitalize md:mb-[1.1875rem] md:pt-0 md:px-0;
    }
    &-read-more {
    @apply md:flex;
    }
    &-read-more .ampost-link {
    @apply relative inline-block align-top pt-0 px-0 pb-[0.1875rem] no-underline uppercase font-bold text-xs leading-[1.125rem] tracking-[0.03125rem] text-blue-1 hover:text-hover focus:shadow-none md:m-auto;
    &:after {
        @apply content-[''] absolute bottom-0 inset-x-0 z-0 border-b border-solid border-current;
    }
    }
}
/* Latest Blog Post Styles */
.pagebuilder-slider {
    &.progress-bar-slider {
        .slick-slider {
            .slick-prev, .slick-next {
                top: calc(50% - 1rem);
            }
            .slick-dots {
                @apply flex !important;
                @apply static md:max-w-[73.5rem] md:mx-auto mt-4 md:mt-8 overflow-hidden items-center;
                li {
                    @apply h-1 w-auto flex-grow m-0;
                    button {
                        @apply w-full h-full rounded-none bg-blue-3;
                    }
                    &.slick-active {
                        button {
                            @apply bg-grey-1;
                        }
                    }
                }
            }
        }
    }
    &.auto-scroll {
        .slick-slider {
            .slick-track {
                &:has(.slick-current[data-index="0"]), &:has(.slick-current[data-index="3"]) {
                    @apply left-6 md:left-0;
                }
                &:has(.slick-current[data-index="1"]), &:has(.slick-current[data-index="4"]) {
                    @apply left-5 md:left-0;
                }
                &:has(.slick-current[data-index="2"]), &:has(.slick-current[data-index="5"]) {
                    @apply left-4 md:left-0;
                }
            }
        }
    }
}
.hero-banner-block,
.narrow-banner-block,
.two-column-image-block,
.three-column-image-block,
.two-column-stack-image-block,
.three-column-stack-image-block,
.side-by-side-image-block,
.side-by-side-video-block,
.full-width-video-block,
.two-column-video-block {
    .heading {
        @apply text-2xl leading-[1.875rem] md:text-3xl md:leading-10 lg:text-[2.5rem] lg:leading-[3.25rem];
    }
    .sub-heading {
        &, strong {
            @apply text-lg leading-[1.6875rem] md:text-base md:leading-6 xl:text-lg xl:leading-[1.6875rem] font-extrabold capitalize;
        }
    }
    .content {
        @apply text-sm leading-6 mb-3.5 md:mb-0 md:text-base xl:text-lg xl:leading-[1.8125rem] font-medium;
    }
    .section-title {
        @apply text-black text-lg leading-[1.6875rem] font-primary font-extrabold capitalize mb-4 md:mb-5;
    }
}
.hero-banner-block {
    @apply py-10 md:py-[3.4375rem];
    &.top-section {
        @apply bg-[#f9f9f9] pt-[1.6875rem] pb-12 md:pt-12 md:bg-transparent;
        div[class*="row_contained"] {
            @apply md:max-w-[90rem] mx-auto max-h-[54.6875rem] max-w-[40.875] overflow-hidden;
        }
        .pagebuilder-slider {
            &.progress-bar-slider {
                @apply md:max-h-[50rem] w-full;
                .slick-slider {
                    .slick-prev, .slick-next {
                        top: calc(50% - 1.375rem);
                        @screen md {
                            top: calc(50% - 2.5rem);
                        }
                    }
                    .slick-dots {
                        @apply ml-0 mt-7 md:mt-8 md:ml-auto;
                    }
                    .slick-list {
                        @apply md:max-h-[26.5625rem] max-h-[50rem] w-full;
                    }
                }
            }
        }
        div.wrapper {
            @apply justify-start md:justify-center md:aspect-[3.2] aspect-[1.223];
            div[class*="banner_content"] {
                @apply pt-[5.1875rem] pb-10 md:py-[1.375rem] justify-start md:justify-center;
            }
            div[class*="banner_collageLeftOverlay"],
            picture[class*="banner_collageLeftOverlay"] ~ div[class*="banner_content"] {
                @apply xl:pl-[5.9375rem] pr-8 md:pr-5 md:h-auto h-[50rem];
            }
            div[class*="banner_collageRightOverlay"],
            picture[class*="banner_collageRightOverlay"] ~ div[class*="banner_content"] {
                @apply pl-8 md:pl-5 xl:pr-[5.9375rem];
            }
        }
        .banner-inner-content {
            @apply gap-y-[0.5625rem] md:gap-y-4 xl:gap-y-[1.5625rem] md:max-w-[35.625rem];
        }
        .content {
            @apply mb-0;
        }
    }
    .pagebuilder-slider {
        @apply pl-6 pr-0 md:px-8 xl:px-10 aspect-[0.68] md:aspect-[9.21];
        .slick-slider {
            .slick-list {
                @apply -mx-[0.3125rem] md:mx-0 aspect-[0.62643] md:aspect-[3.1236];
            }
            .slick-slide {
                @apply mx-[0.3125rem] md:mx-0 relative;
                /* styles to resolve flicker issue */
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .slick-track {
                @apply flex max-h-[26.5625rem];
                &:has(.slick-current:last-child:not(:first-child)) {
                    @apply -left-6 md:left-0;
                }
            }
            .slick-prev {
                @apply -left-[0.6875rem] md:-left-4;
            }
            .slick-next {
                @apply right-3.5 md:-right-4;
            }
            .slick-dots {
                @apply ml-[0.1875rem] mr-6 md:ml-auto md:mr-auto;
            }
        }
        /* style to improve homepage CLS score */
        &.auto-scroll {
            &:not(.no-transform) {
                .slick-slider {
                    .slick-track:not([style*="transform: translate3d"]) {
                        .slick-slide:not(.slick-current) {
                            @apply md:invisible;
                        }
                    }
                }
            }
            &.no-transform {
                .slick-slider {
                    .slick-track:not([style*="opacity: 1"]) {
                        .slick-slide:not(.slick-current) {
                            @apply md:invisible;
                        }
                    }
                }
            }
        }
    }
    div.wrapper {
        @apply aspect-[0.62643] md:aspect-[2.21136];
        div[class*="banner_content"] {
            @apply flex flex-col justify-center px-[1.125rem] md:px-[1.875rem] md:pb-[1.375rem] md:max-w-none;
        }
        div[class*="banner_collageLeftOverlay"],
        picture[class*="banner_collageLeftOverlay"] ~ div[class*="banner_content"] {
            @apply px-[1.125rem] md:pl-10 lg:pl-16 xl:pl-[7.25rem] md:pr-[1.875rem];
        }
        div[class*="banner_collageRightOverlay"],
        picture[class*="banner_collageRightOverlay"] ~ div[class*="banner_content"] {
            @apply px-[1.125rem] md:pl-[1.875rem] md:pr-10 lg:pr-16 xl:pr-[7.25rem];
        }
    }
    .banner-inner-content {
        @apply inline-flex flex-col gap-y-3 md:gap-y-4 xl:gap-y-[1.5625rem] md:max-w-[33.75rem];
    }
}
.narrow-banner-block {
    @apply px-6 md:px-8 xl:px-10 py-10 md:py-[3.4375rem];
    div.wrapper {
        @apply aspect-[0.7451] md:aspect-[4.39];
        div[class*="banner_content"] {
            @apply flex flex-col justify-center px-[1.125rem] md:px-[1.875rem] md:pt-5 md:pb-8 md:max-w-none;
        }
        div[class*="banner_collageLeftOverlay"],
        picture[class*="banner_collageLeftOverlay"] ~ div[class*="banner_content"] {
            @apply pl-4 pr-5 md:pl-10 lg:pl-16 xl:pl-[4.8125rem] md:pr-[1.875rem];
        }
        div[class*="banner_collageRightOverlay"],
        picture[class*="banner_collageRightOverlay"] ~ div[class*="banner_content"] {
            @apply pl-5 pr-4 md:pl-[1.875rem] md:pr-10 lg:pr-16 xl:pr-[4.8125rem];
        }
    }
    .banner-inner-content {
        @apply inline-flex flex-col gap-y-3 md:gap-y-[0.9375rem] md:max-w-[33.75rem];
    }
    .text-link {
        @apply text-xs leading-[0.875rem] pb-1;
    }
}
.two-column-image-block,
.three-column-image-block {
    @apply px-6 md:px-8 xl:px-10 py-10 md:py-[3.4375rem];
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply gap-y-10 gap-x-5;
        }
    }
    .pagebuilder-column {
        @apply aspect-[0.69279] md:aspect-[1.08942] py-8 px-[1.0625rem] md:px-5 gap-y-2.5 md:gap-y-[1.5625rem];
        > div {
            @apply md:max-w-[27.5rem] md:mx-auto md:w-full;
        }
        .pb-text-root {
            @apply flex flex-col gap-y-2.5 md:gap-y-[1.5625rem];
        }
        div[class*="buttons_root"] {
            @apply md:-mb-2.5;
        }
    }
}
.three-column-image-block {
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply md:px-[0.09375rem];
        }
    }
    .pagebuilder-column {
        @apply aspect-[0.69279] md:aspect-[0.707] md:gap-y-5;
        .pb-text-root {
            @apply text-left md:text-center md:gap-y-5;
        }
        div[class*="buttons_root"] {
            @apply text-left md:text-center mb-0;
        }
        .btn-primary {
            @apply md:-ml-1;
        }
    }
    &.narrow-image {
        .pagebuilder-column {
            @apply aspect-square;
            figure img {
                @apply w-full h-full object-cover;
            }
        }
        &.cms-3-column-image {
            @apply my-0 py-8 px-6 md:py-10 xl:py-[3.125rem] md:px-5 lg:px-8 xl:px-[2.5625rem];
            div[class*="columnGroup_root"] {
                > div[class*="columnGroup_root"] {
                    @apply gap-y-4 md:px-0;
                }
            }
            .pagebuilder-column {
                &[style*="justify-content: flex-start"] {
                    @apply justify-center md:justify-start !important;
                    @apply md:pt-[1.125rem] pl-[1.125rem] pb-10 md:pb-5 md:pl-5;
                }
                &[style*="justify-content: flex-end"] {
                    @apply justify-center md:justify-end !important;
                    @apply md:pb-[1.375rem] pr-[2.625rem] md:pr-5;
                    .content {
                        @apply md:max-w-[17.125rem] md:mx-auto;
                    }
                }
                &[style*="justify-content: center"] {
                    @apply pl-[1.4375rem] md:pl-5;
                }
            }
        }
    }
}
.two-column-stack-image-block,
.three-column-stack-image-block {
    @apply px-6 md:px-8 xl:px-10 py-10 md:py-[3.4375rem];
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply gap-y-10 gap-x-5;
        }
    }
    .pagebuilder-column {
        @apply gap-y-2.5 md:gap-y-[1.5625rem];
        > div {
            @apply md:max-w-[34.875rem];
        }
        .pb-text-root {
            @apply flex flex-col gap-y-2.5 md:gap-y-[1.5625rem];
        }
        div[class*="buttons_root"] {
            @apply md:mb-0;
        }
    }
}
.two-column-stack-image-block {
    .pagebuilder-column {
        figure {
            @apply aspect-[1.1047] md:aspect-[1.08942] relative md:mb-[0.6875rem];
            img {
                @apply absolute inset-0 w-full h-full z-0 object-cover;
            }
        }
        .content {
            @apply mb-0;
        }
        .btn-primary, .btn-secondary {
            @apply min-w-[11.5rem];
        }
    }
}
.three-column-stack-image-block {
    @apply pl-[1.5625rem] pr-0 md:px-8 xl:px-10;
    .pagebuilder-slider {
        @apply px-0;
        .slick-list {
            @apply -mx-2.5;
        }
        .slick-track {
            @apply flex;
            &:has(.slick-current:last-child:not(:first-child)) {
                @apply -left-6 md:left-0;
            }
        }
        .slick-slide {
            @apply mx-2.5;
        }
        picture {
            @apply aspect-[0.7138] md:aspect-[0.715] relative mb-[1.125rem] md:mb-[1.5625rem];
            img {
                @apply absolute inset-0 z-0 w-full h-full object-cover;
            }
        }
        div.wrapper {
            div[class*="banner_content"] {
                @apply max-w-full p-0 bg-white;
            }
            &[style*="background-color"] {
                &:before {
                    @apply content-[''] w-full relative block bg-inherit aspect-[0.7138] md:aspect-[0.715];
                }
                .banner-inner-content {
                    @apply pt-[1.125rem] md:pt-[1.5625rem];
                }
            }
        }
        .banner-inner-content {
            @apply flex flex-col gap-y-[1.125rem] md:gap-y-[1.5625rem];
        }
        .heading {
            @apply font-primary text-xl leading-[1.625rem] font-extrabold md:text-2xl md:leading-[1.9375rem] tracking-normal md:font-secondary md:font-bold pr-5 md:pr-0;
        }
        .content {
            @apply mb-0;
        }
        .btn-primary, .btn-secondary {
            @apply min-w-[10.875rem] md:min-w-[14.625rem];
        }
        &.progress-bar-slider {
            .slick-slider {
                .slick-prev, .slick-next {
                    top: calc(50% - 3rem);
                    &.slick-disabled {
                        @apply opacity-0;
                    }
                }
                .slick-prev {
                    @apply -left-[0.8125rem] md:left-0;
                }
                .slick-next {
                    @apply right-[0.8125rem] md:right-0;
                }
                .slick-dots {
                    @apply mt-5 mr-[1.5625rem] md:mt-8 md:mr-0;
                }
            }
        }
    }
    &.narrow-image {
        .pagebuilder-slider {
            picture {
                @apply aspect-square;
            }
            div.wrapper {
                &[style*="background-color"] {
                    &:before {
                        @apply aspect-square;
                    }
                }
            }
        }
    }
}
.side-by-side-image-block,
.side-by-side-video-block {
    @apply px-6 md:px-8 xl:px-10 py-10 md:py-[3.4375rem];
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply gap-y-5 md:gap-x-6 xl:gap-x-10 md:justify-between;
        }
    }
    .pagebuilder-column {
        @apply gap-y-2.5 md:gap-y-[0.9375rem];
        .pb-text-root {
            @apply flex flex-col gap-y-2.5 md:gap-y-[0.9375rem] md:max-w-[34.5rem];
        }
        .heading {
            @apply font-primary font-extrabold text-xl leading-[1.625rem] md:font-secondary md:font-bold md:text-2xl md:leading-[1.9375rem] tracking-normal;
        }
        .sub-heading {
            &, strong {
                @apply text-base leading-[1.6875rem] font-extrabold capitalize;
            }
        }
        .content {
            @apply text-sm leading-6 flex flex-col gap-y-5 mb-0 md:gap-y-[1.5625rem];
        }
        div[class*="buttons_root"] {
            @apply md:max-w-[34.5rem];
        }
        &.image-column {
            @apply order-first md:order-none md:max-w-[42.5rem];
            figure {
                @apply aspect-[1.09] md:aspect-[1.10568] relative;
                img {
                    @apply absolute inset-0 w-full h-full object-cover z-0;
                }
            }
        }
        &.content-column {
            @apply md:max-w-[40rem];
            &:last-child {
                @apply md:pl-5;
                .pb-text-root {
                    @apply md:max-w-[32.5rem] md:mb-0;
                }
                .btn-primary, .btn-secondary {
                    @apply md:min-w-[10.875rem];
                }
            }
        }
    }
}
.side-by-side-video-block {
    @apply md:py-[2.625rem];
    .pagebuilder-column {
        &.video-column {
            @apply order-first md:order-none md:max-w-[41.875rem];
            div[class*="video_container"] {
                @apply md:pt-[60.15%];
            }
            .yt-lite {
                &:after {
                    @apply md:pb-[60.15%];
                }
            }
        }
        &.content-column {
            &:last-child {
                @apply md:max-w-[40.625rem] md:pt-2.5;
                .pb-text-root, div[class*="buttons_root"] {
                    @apply md:max-w-full;
                }
                .btn-primary, .btn-secondary {
                    @apply md:min-w-[11.3125rem];
                }
            }
            .pb-text-root {
                @apply md:mb-0;
            }
            .btn-primary, .btn-secondary {
                @apply md:min-w-[11.3125rem];
            }
        }
    }
}
.full-width-video-block {
    @apply px-6 md:px-8 xl:px-10 py-10 md:py-[2.75rem];
    div[class*="video_container"] {
        @apply pt-[66.67%] md:pt-[54.93%];
    }
    .yt-lite {
        &:after {
            @apply pb-[66.67%] md:pb-[54.93%];
        }
    }
    .pb-text-root {
        @apply mt-3;
    }
    .sub-heading {
        @apply mb-3.5;
    }
}
.two-column-video-block {
    @apply px-6 md:px-8 xl:px-10 py-10 md:py-[3.4375rem];
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply gap-y-5 md:gap-x-5;
        }
    }
    div[class*="video_container"] {
        @apply pt-[66.67%] md:pt-[59.10%];
    }
    .yt-lite {
        &:after {
            @apply pb-[66.67%] md:pb-[59.10%];
        }
    }
    .pb-text-root {
        @apply md:max-w-[35.3125rem];
    }
    div[class*="video_root"] {
        @apply mb-6;
    }
    .sub-heading {
        @apply mb-3;
    }
}
.svg-tile-block, .image-tile-block {
    @apply px-6 lg:px-8 xl:px-10 my-10 md:my-[3.4375rem];
    .five-col-grid {
        @apply list-none pl-0 grid grid-cols-2 pt-[1.6875rem] pb-[1.625rem] gap-y-[2.4375rem] gap-x-5 md:flex md:flex-wrap md:max-w-[74.125rem] md:mx-auto md:justify-center items-center md:gap-x-[2%] lg:gap-x-[3.25%] md:gap-y-5 md:pt-[1.1875rem] md:pb-[1.0625rem];
        li {
            @apply flex flex-col gap-y-5 items-center max-w-[9.625rem] mx-auto md:w-[18.4%] lg:w-[17.4%] lg:max-w-[12.875rem] md:mx-0 text-center;
            svg {
                @apply max-w-full h-auto;
            }
            a {
                @apply text-xs leading-[0.875rem] underline underline-offset-2;
                &:after {
                    @apply hidden;
                }
            }
        }
    }
}
.svg-tile-block {
    &.one-row-block {
        @apply pr-0 md:pr-6 lg:pr-8 xl:pr-10;
        .five-col-grid {
            @apply list-none pl-0 block whitespace-nowrap overflow-x-auto max-w-[76.875rem] pt-[1.5625rem] pb-[0.8125rem] md:pt-3.5 md:pb-1.5;
            li {
                @apply inline-flex flex-row gap-x-2.5 md:gap-x-3 w-auto max-w-none mr-[1.875rem] md:mr-[2.1875rem];
                &:last-child {
                    @apply md:mr-0;
                }
                svg {
                    @apply max-w-[2rem] md:max-w-[3rem];
                    + a {
                        max-width: calc(100% - 2.625rem);
                        @screen md {
                            max-width: calc(100% - 3.125rem);
                        }
                    }
                }
            }
        }
    }
    &.single-row-block {
        @apply my-0 px-0 md:px-4 lg:px-8 xl:px-[2.9375rem] min-h-[10.1875rem] md:min-h-[16.4375rem];
        .five-col-grid {
            @apply list-none pl-0 flex flex-nowrap py-[1.3125rem] md:py-12 max-w-full gap-x-3 lg:gap-x-5 xl:gap-x-6 overflow-x-auto items-start justify-start md:grid md:grid-cols-5;
            li {
                @apply w-auto min-w-[15.625rem] max-w-[15.625rem] gap-y-2 px-1.5 md:min-w-0;
                a {
                    @apply text-base font-medium capitalize text-blue-1 font-primary text-center tracking-normal pb-0 no-underline hover:text-blue-1;
                }
            }
        }
    }
}
.image-tile-block {
    .five-col-grid {
        @apply md:py-3;
    }
    &.cms-image-tile {
        @apply my-0 py-8 px-12 md:pt-[1.625rem] md:pb-4 md:px-5 lg:px-9;
        .six-col-grid {
            @apply list-none pl-0 grid grid-cols-2 gap-y-[1.875rem] gap-x-5 md:flex md:justify-center items-center md:gap-y-0 md:gap-x-6 lg:gap-x-8 xl:gap-x-[3.125rem];
            li {
                img {
                    @apply max-w-[8.125rem] block mx-auto md:max-w-[6.25rem] lg:max-w-[8.125rem];
                    &[alt*="Since 2003 Logo"] {
                        @apply max-w-[5.375rem] md:max-w-[6.25rem] lg:max-w-[8.125rem];
                    }
                }
            }
        }
    }
}
.cms-table-block {
    @apply px-6 lg:px-8 xl:px-10 py-10 md:py-[3.4375rem];
    .table-wrapper {
        &.responsive-table {
            @apply overflow-x-auto;
            table {
                th:first-child {
                    @apply md:sticky md:left-0;
                }
            }
        }
    }
    table {
        @apply w-full md:border md:border-solid md:border-grey-4;
        thead {
            @apply hidden md:table-header-group;
        }
        th , td {
            @apply text-xs leading-[1.125rem] py-2.5 px-3 text-center -tracking-[0.015625rem] md:w-[12.125rem];
            &:not(:last-child) {
                @apply md:border-r md:border-solid md:border-grey-4;
            }
        }
    }
    thead th, tbody th {
        @apply bg-grey-5 font-bold text-blue-1;
    }
    tbody {
        @apply block md:table-row-group;
        tr {
            @apply flex flex-col mb-10 border border-solid border-grey-4 md:table-row md:mb-0 md:border-0;
            &:last-child {
                @apply mb-0;
            }
        }
        td {
            &[data-th] {
                @apply flex items-center p-0 md:py-2.5 md:px-3 md:table-cell;
                &:before {
                    @apply block content-[attr(data-th)] bg-grey-5 font-bold text-xs leading-[0.875rem] uppercase tracking-[0.03125rem] text-blue-1 py-2.5 px-3 w-2/5 min-w-[8.375rem] border-r border-solid border-grey-4 md:hidden;
                }
                span {
                    @apply w-3/5 flex-grow text-center md:w-auto;
                }
            }
        }
        th, td {
            @apply border-t border-solid border-grey-4;
        }
        th {
            @apply md:uppercase;
        }
    }
}
.cms-tabs-block {
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.25);
    @apply py-10 px-6 lg:px-8 xl:px-10 my-10 md:my-[3.4375rem] md:min-h-[28rem];
    div[class*="row_contained"] {
        @apply max-w-[69rem];
    }
    div[class*="tabs_root"] {
        ul[class*="tabs_navigation"] {
            li[class*="tabs_header"] {
                @apply text-xs leading-[0.875rem] p-[0.9375rem] max-w-[17.25rem] truncate uppercase tracking-[0.03125rem] font-bold text-blue-4;
                &[class*="tabs_selected"] {
                    @apply text-blue-1;
                }
            }
        }
    }
    &.dark-tabs {
        @apply shadow-none;
        div[class*="tabs_root"] {
            ul[class*="tabs_navigation"] {
                li[class*="tabs_header"] {
                    @apply md:max-w-[17.6875rem] p-4 text-blue-2;
                    &[class*="tabs_selected"] {
                        @apply text-white;
                    }
                }
            }
        }
    }
    &.desktop-only {
        @apply md:flex !important;
    }
}
.cms-accordion-block {
    @apply my-0 py-10 md:py-[3.4375rem];
    &[class*="row_contained"], [class*="row_contained"] {
        @apply max-w-[73.125rem];
    }
    .accordion-wrapper {
        @apply border-t border-solid border-blue-4;
    }
    .tabs {
        @apply border-b border-solid border-blue-4;
    }
    .tab-radio {
        @apply w-full h-full inset-0 z-1;
        &:checked {
            ~ .tab-header {
                &:after {
                    @apply -rotate-180;
                }
            }
        }
    }
    .tab-header {
        @apply py-[1.1875rem] px-6 md:py-[1.4375rem] md:px-5 gap-x-4;
        &:after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.8488 8.26646L0.269462 2.03713C-0.0898191 1.67446 -0.089819 1.11979 0.269463 0.77846C0.628744 0.437126 1.23503 0.437126 1.59431 0.77846L7.5 6.38913L13.4057 0.778461C13.7874 0.437127 14.3713 0.437128 14.7305 0.778461C15.0898 1.11979 15.0898 1.67446 14.7305 2.03713L8.17365 8.26646C7.79192 8.6078 7.20808 8.6078 6.8488 8.26646Z" fill="%234D4D4D"/></svg>');
            @apply content-[''] block self-start w-[0.9375rem] h-[0.5625rem] bg-no-repeat bg-contain transform transition-transform duration-200;
        }
    }
    .tab-title {
        @apply font-primary font-bold text-sm leading-[1.0625rem] uppercase md:text-base md:leading-6 md:font-extrabold text-blue-1 tracking-normal md:normal-case overflow-hidden;
        display: -webkit-box;
        max-width: calc(100% - 2.25rem);
        line-clamp: 2;
        -webkit-line-clamp: 2;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        @screen md {
            max-width: calc(100% - 6.125rem);
        }
    }
    .tab-content {
        @apply relative z-2;
    }
    .tab-content-inner {
        @apply flex flex-col gap-y-[1.5625rem] pt-1.5 pb-[1.1875rem] pl-6 pr-[1.625rem] md:pt-3.5 md:pb-[1.3125rem] md:px-5 md:max-w-[67.1875rem];
    }
    &.accordion-with-header {
        @apply pt-[1.875rem] pb-0 md:py-[3.4375rem] xl:py-[4.75rem];
        .heading {
            @apply mb-3.5 pl-5 pr-11 md:mb-6 md:px-5 xl:px-0;
        }
        .tab-header {
            &:after {
                @apply mt-1 md:mt-0;
            }
        }
    }
}
.full-width-content-block, .two-column-content-block {
    @apply px-6 py-10 md:py-[3.4375rem];
    &[class*="row_contained"] {
        @apply max-w-[73.4375rem];
    }
    > div, .pb-text-root {
        @apply flex flex-col gap-y-2.5 md:gap-y-[1.5625rem];
    }
    .heading {
        @apply font-primary font-extrabold text-xl leading-[1.625rem] md:font-secondary md:font-bold md:text-2xl md:leading-[1.9375rem] tracking-normal;
    }
    .sub-heading {
        &, strong {
            @apply text-base leading-[1.6875rem] font-extrabold capitalize;
        }
    }
    .content {
        @apply text-sm leading-6 flex flex-col gap-y-6 mb-0 md:gap-y-[1.5625rem];
    }
    .btn-primary, .btn-secondary {
        @apply min-w-[10.875rem];
    }
}
.two-column-content-block {
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply gap-y-10 md:gap-x-5;
        }
    }
    .pb-text-root {
        @apply md:gap-y-4;
    }
    .pagebuilder-column {
        @apply gap-y-2.5 md:gap-y-5;
    }
}
.four-column-tile-block {
    .pagebuilder-slider {
        @apply pl-5 pr-0 md:px-6 lg:px-8 xl:px-10 my-10 md:my-[3.4375rem];
        .slick-slider {
            .wrapper {
                @apply gap-y-[0.9375rem];
                picture {
                    @apply static aspect-square;
                    ~ div[class*="banner_content"] {
                        @apply p-0;
                        .banner-inner-content {
                            @apply text-left md:text-center flex flex-col gap-y-[0.9375rem];
                        }
                    }
                }
            }
        }
        .slick-list {
            @apply -mx-2.5;
        }
        .slick-track {
            @apply flex;
            &:has(.slick-current:last-child:not(:first-child)) {
                @apply -left-6 md:left-0;
            }
        }
        .slick-slide {
            @apply mx-2.5;
        }
        .heading {
            @apply text-sm leading-[1.125rem] text-grey-1 font-primary font-extrabold md:text-base md:leading-[1.6875rem] capitalize;
        }
        .sub-heading {
            &, strong {
                @apply text-blue-4 uppercase text-xs leading-[0.875rem] font-bold tracking-[0.03125rem];
            }
        }
        .content {
            @apply text-black text-xs leading-6 md:text-sm md:leading-6;
        }
        .text-link {
            @apply text-xs leading-[0.875rem] pb-0.5;
        }
        &.progress-bar-slider {
            .slick-slider {
                .slick-prev, .slick-next {
                    top: calc(50% - 3rem);
                    &.slick-disabled {
                        @apply opacity-0;
                    }
                }
                .slick-prev {
                    @apply -left-[0.8125rem] md:left-0;
                }
                .slick-next {
                    @apply right-[0.8125rem] md:right-0;
                }
                .slick-dots {
                    @apply mt-5 mr-[1.5625rem] md:mt-8 md:mr-0;
                }
            }
        }
    }
}
.cms-form-block {
    @apply py-10 xl:pt-[5.25rem] xl:pb-16 my-0;
    div[class*="row_contained"] {
        @apply max-w-[73.875rem] px-6;
    }
    .md-2column-form {
        form {
            @apply md:grid md:grid-cols-2 md:gap-y-8 md:gap-x-5;
        }
    }
    form {
        @apply flex flex-col gap-y-[1.875rem];

        .hs-form-field {
            label:not(.hs-form-radio-display):not(.hs-error-msg) {
                @apply block uppercase text-xs leading-[0.875rem] tracking-[0.03125rem] font-bold text-grey-1 mb-2 relative;
                .hs-form-required {
                    @apply text-red;
                }
            }
            &.hs-usgb_pa_amount, &.hs_usgb_pa_comments {
                > label:not(.hs-form-radio-display):not(.hs-error-msg) {
                    @apply mb-5 md:mt-5;
                }
            }
        }

        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='email'],
        input[type='password'],
        select,
        .form-select,
        textarea {
            @apply appearance-none w-full border border-solid border-grey-1 bg-white text-black py-[0.6875rem] px-4 font-medium text-xs leading-[0.875rem] touch:text-base mb-0.5 rounded-none md:py-4 focus:shadow-none;

            &::placeholder {
                @apply text-grey-1;
            }

            &:focus {
                @apply border-gold-1 outline-2 outline outline-gold-1;
                &::placeholder {
                    @apply text-grey-4;
                }
            }

            &.error {
                @apply border-red outline-none;
                &::placeholder {
                    @apply text-grey-2;
                }
                &:focus {
                    @apply border-red border outline-none;
                    &::placeholder {
                        @apply text-grey-2;
                    }
                }
            }

            &.disabled,
            &:disabled {
                @apply outline-none border-grey-9;
                &::placeholder {
                    @apply text-grey-9;
                }
                &:focus {
                    @apply border border-grey-9 outline-none;
                    &::placeholder {
                        @apply text-grey-9;
                    }
                }
            }
        }

        select {
            background-image: url('data:image/svg+xml,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.63681 8.41063L15.0699 2.18129C15.4212 1.81863 15.4212 1.26396 15.0699 0.922626C14.7186 0.581292 14.1258 0.581292 13.7745 0.922626L8.00008 6.53329L2.22563 0.922626C1.85238 0.581293 1.28152 0.581293 0.930221 0.922626C0.578924 1.26396 0.578924 1.81863 0.930221 2.18129L7.3414 8.41063C7.71465 8.75196 8.28551 8.75196 8.63681 8.41063Z" fill="black" stroke="black" stroke-width="0.5"/></svg>');
            background-position: 97% 50%;
            background-size: 1rem 0.625rem;
            @apply bg-no-repeat pr-9;
            &.disabled,
            &:disabled {
                background-image: url('data:image/svg+xml,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.63681 8.41063L15.0699 2.18129C15.4212 1.81863 15.4212 1.26396 15.0699 0.922626C14.7186 0.581292 14.1258 0.581292 13.7745 0.922626L8.00008 6.53329L2.22563 0.922626C1.85238 0.581293 1.28152 0.581293 0.930221 0.922626C0.578924 1.26396 0.578924 1.81863 0.930221 2.18129L7.3414 8.41063C7.71465 8.75196 8.28551 8.75196 8.63681 8.41063Z" fill="rgb(130 130 130)" stroke="rgb(130 130 130)" stroke-width="0.5"/></svg>');
                @apply text-grey-9;
            }
        }

        textarea {
            @apply min-h-[17.6875rem];
        }

        input[type="checkbox"] {
            min-width: 1.25rem;
            @apply h-5 w-5 appearance-none align-middle border border-solid border-grey-1 bg-white rounded-none mr-2.5 mt-0.5;
            + label, + span {
                @apply align-middle text-sm leading-6 text-grey-1;
            }

            &:checked {
                background-image: url('data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L2.33333 4.75L3.66667 6L9 1" stroke="%236D4B0F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                background-size: 0.625rem 0.4375rem;
                @apply bg-no-repeat bg-center;
            }

            &:disabled,
            &.disabled {
                @apply border-grey-9;

                + label, + span {
                    @apply text-grey-9;
                }
            }
        }

        input[type="radio"] {
            @apply select-none rounded-full appearance-none align-middle border border-solid border-grey-1 bg-white h-5 w-5 mr-2.5;

            + label,
            + span {
                @apply align-middle text-sm leading-6 text-grey-1;
            }

            &:checked {
                background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill="%236D4B0F"/></svg>');
                background-size: 0.875rem 0.875rem;
                @apply bg-no-repeat bg-center;
            }

            &:disabled,
            &.disabled {
                @apply border-grey-9;

                + label,
                + span {
                    @apply text-grey-9;
                }
            }
        }

        .inputs-list.multi-container {
            @apply flex flex-col gap-y-5;
        }

        .legal-consent-container, .hs-submit {
            @apply md:col-span-2;
        }

        .hs-submit {
            @apply md:text-right;
            input[type="submit"] {
                @apply cursor-pointer no-underline m-0 py-[0.1875rem] px-[1.5625rem] lg:px-[2.0625rem] rounded-[3.125rem] text-sm leading-[1.8125rem] font-primary font-bold tracking-[0.03125rem] outline-none border-0 bg-blue-1 text-white focus:shadow-none transform transition-all duration-200 uppercase min-w-full md:min-w-[20.375rem];
                &.disabled, &[disabled] {
                    @apply border-0 bg-grey-7 text-white opacity-100 pointer-events-none;
                    &:active,
                    &:focus {
                        @apply bg-grey-7 text-white;
                    }
                }
                &:active,
                &:focus {
                    @apply border-0 bg-blue-1 text-white;
                }
                &:hover {
                    @apply border-0 bg-hover text-white transform transition-all duration-200;
                }
            }
        }

        .hs-error-msg {
            @apply text-grey-1 text-xs leading-[1.125rem] -tracking-[0.015625rem] inline-flex gap-2.5;
            &:before {
                background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" fill="%23E2204F" stroke="%23E2204F" stroke-width="0.7"/><path d="M6.31724 3.75816L6.42662 8.16962H7.57506L7.68444 3.75816H6.31724ZM7.00084 10.4483C7.41555 10.4483 7.74367 10.1338 7.74367 9.73277C7.74367 9.33173 7.41555 9.01727 7.00084 9.01727C6.58613 9.01727 6.258 9.33173 6.258 9.73277C6.258 10.1338 6.58613 10.4483 7.00084 10.4483Z" fill="white"/><path d="M6.3171 3.37637H5.92571L5.9354 3.76766L6.04478 8.17911L6.05401 8.55147H6.42648H7.57492H7.94739L7.95662 8.17911L8.06599 3.76766L8.07569 3.37637H7.6843H6.3171ZM7.0007 10.8301C7.6121 10.8301 8.12536 10.3586 8.12536 9.7328C8.12536 9.10697 7.6121 8.63549 7.0007 8.63549C6.3893 8.63549 5.87604 9.10697 5.87604 9.7328C5.87604 10.3586 6.3893 10.8301 7.0007 10.8301Z" fill="white" stroke="%23E2204F" stroke-width="0.7"/></svg>');
                @apply content-[''] bg-no-repeat bg-contain w-3 h-3 mt-[0.1875rem] min-w-[0.75rem];
            }
        }

        .hs_error_rollup .hs-main-font-element {
            @apply hidden;
        }
    }
    &.cms-hubspot-form {
        @apply xl:pt-[3.875rem] xl:pb-[4.4375rem];
        div[class*="row_contained"] {
            @apply flex flex-wrap md:flex-nowrap md:justify-end md:gap-x-4 px-6 xl:px-8;
        }
        .cms-form-content {
            @apply md:max-w-[24.75rem] md:pt-10 lg:pr-[1.125rem] xl:pr-6 xl:relative xl:right-[6.375rem] xl:pt-[3.75rem];
        }
        .heading {
            @apply mb-5;
        }
        .sub-heading {
            @apply text-base mb-7 md:text-lg md:leading-[1.6875rem] capitalize font-extrabold md:pr-2 md:mb-0;
            strong {
                @apply font-extrabold;
            }
        }
        .cms-claim-offer-form {
            @apply md:max-w-[26.5rem];
        }
        .free-investor-kit {
            > .container {
                @apply hidden;
            }
        }
        form {
            @apply gap-y-[1.125rem];
            input[type="text"], input[type="tel"], input[type="email"] {
                &::placeholder {
                    @apply text-grey-2 uppercase;
                }
            }
            .hs-form-field label:not(.hs-form-radio-display):not(.hs-error-msg) {
                @apply text-white;
                &:has(span:empty) {
                    @apply mb-0;
                }
                .hs-form-required {
                    @apply text-red;
                }
            }
            ul.hs-error-msgs {
                > li {
                    @apply bg-white;
                }
            }
            .legal-consent-container {
                @apply text-white;
            }
            .hs_error_rollup {
                @apply hidden;
            }
        }
        .btn-primary-type-2 form .hs-submit {
            @apply text-left;
            .actions {
                @apply inline-block w-full relative mt-2.5 mb-1 md:w-full;
                  &:before {
                    @apply content-[''] absolute pointer-events-none z-1 inset-0 border-[0.1875rem] border-solid border-hover rounded-[3.125rem] opacity-0 transform transition-opacity duration-200;
                  }
                  &:hover {
                    &:before {
                      @apply opacity-100;
                    }
                    input[type="submit"] {
                        @apply border-0 text-t-4 bg-primary transform transition-all duration-200 w-full;
                        text-shadow: 0.025rem 0 0 currentColor;
                    }
                  }
                input[type="submit"] {
                    @apply w-full pt-2.5 px-[1.875rem] pb-[0.6875rem] bg-white text-t-4 text-xs leading-[0.875rem] uppercase tracking-[0.03125rem] font-bold;
                    &.disabled, &[disabled] {
                        @apply border-0 bg-grey-2 text-secondary opacity-100 pointer-events-none;
                        &:before {
                          @apply hidden;
                        }
                        &:active,
                        &:focus {
                            @apply bg-grey-2 text-secondary;
                        }
                    }
                    &:active,
                    &:focus {
                        @apply border-0 text-t-4 bg-primary;
                    }
                }
            }
        }
        .hs_tcpa_form_consent_granted .input {
            @apply h-full overflow-hidden;
            label {
                @apply overflow-hidden;
                input {
                    @apply float-left mt-0;
                }
                span {
                    @apply float-left w-11/12 text-xs normal-case font-normal text-white;
                }
            }
            a {
                @apply underline;
              }
        }
    }
}
.cms-top-categories-block {
    @apply py-12 px-5 md:px-6 md:py-11;
    .heading {
        @apply text-black font-primary font-extrabold capitalize text-lg leading-[1.625rem] tracking-normal mb-[0.9375rem] md:mb-3.5 md:text-blue-1 md:text-2xl md:leading-[1.9375rem] md:font-secondary md:font-bold md:uppercase;
    }
    div[class*="columnGroup_root"] {
        > div[class*="columnGroup_root"] {
            @apply justify-center gap-x-5 gap-y-[0.9375rem] max-w-sm mx-auto md:mx-0 md:max-w-full md:gap-y-0 md:gap-x-6 flex-wrap md:flex-nowrap;
        }
    }
    .pagebuilder-column {
        @apply basis-auto md:max-w-[12.75rem] md:gap-y-4;
        @media (max-width: 47.9375rem) {
            @apply w-auto !important;
        }
    }
    figure {
        @apply aspect-square;
        a {
            @apply block;
        }
        img {
            @apply w-full h-full object-cover;
        }
    }
    .sub-heading {
        @apply text-grey-1 text-lg leading-[1.6875rem] capitalize;
        &, strong {
            @apply font-extrabold;
        }
    }
    div.text-link {
        @apply pb-0 after:hidden;
        html & a {
            @apply text-xs leading-[1.375rem] pb-[0.3125rem];
        }
    }
    html & .btn-primary-type-green {
        button.btn-primary {
            @apply px-4 bg-[#E3EADD] text-black hover:bg-[#E3EADD] hover:text-black;
        }
    }
}
.cms-shop-by-block {
    @apply my-0 py-[1.875rem] pl-6 md:py-10 xl:py-[3.125rem] md:px-5 lg:px-8 xl:px-[2.6875rem] overflow-hidden;
    .pagebuilder-slider {
        @apply px-0;
        &.progress-bar-slider {
            .slick-slider {
                .slick-dots {
                    @apply mt-[1.6875rem] mr-6 md:mr-auto;
                }
                &:has(.slick-current:nth-last-child(2):not(:first-child)) {
                    .slick-dots {
                        li:last-child {
                            button {
                                @apply bg-blue-1;
                            }
                        }
                    }
                }
            }
        }
    }
    .heading {
        @apply text-2xl leading-[1.875rem] mb-[1.875rem] -ml-6 md:ml-0 md:leading-[1.9375rem] md:tracking-normal md:mb-5;
    }
    .slick-slider {
        .slick-list {
            @apply -mx-[0.3125rem] md:-mx-2.5 lg:-mx-[0.8125rem];
        }
        .slick-track {
            @apply flex;
            &:has(.slick-current:nth-last-child(2):not(:first-child)) {
                @apply -left-6 md:left-0;
            }
        }
        .slick-slide {
            @apply mx-[0.3125rem] md:mx-2.5 lg:mx-[0.8125rem];
        }
        .wrapper {
            picture {
                @apply static aspect-square;
                ~ div[class*="banner_content"] {
                    @apply p-0 m-0 text-left md:text-center w-full max-w-none;
                }
            }
        }
        .banner-inner-content {
            .sub-heading {
                @apply my-[0.9375rem] text-grey-1 font-extrabold capitalize text-sm leading-[1.125rem] pr-2.5 md:pr-0 lg:text-base lg:leading-6 xl:text-lg xl:leading-[1.6875rem];
                strong {
                    @apply font-extrabold;
                }
            }
            .text-link {
                @apply leading-[1.375rem];
            }
        }
    }
}
.cms-testimonal-block {
    @apply relative overflow-hidden py-8 pl-[2.0625rem] md:pt-[2.0625rem] md:pb-14 md:px-6 lg:px-8 xl:pl-[2.4375rem] xl:pr-[2.5625rem];
    .section-title {
        @apply text-black text-lg leading-[1.6875rem] font-primary font-extrabold capitalize mb-3 pr-6 md:pr-0 md:mb-[1.125rem];
    }
    .pagebuilder-slider {
        @apply px-0;
    }
    .slick-slider {
        @apply static;
        .wrapper {
            @apply aspect-[0.61] md:aspect-[3.74] py-10 px-[1.40625rem] md:px-6;
            div[class*="banner_content"] {
                @apply w-full p-0;
            }
        }
        .slick-list {
            @apply -mx-1.5 md:mx-0;
        }
        .slick-track {
            @apply flex;
            &:has(.slick-current:last-child:not(:first-child)) {
                @apply -left-[1.40625rem] md:left-0;
            }
        }
        .slick-slide {
            @apply mx-1.5 md:mx-0;
        }
        .slick-prev, .slick-next {
            @apply top-[53.3%] md:top-[52.5%];
        }
        .slick-prev {
            @apply left-2.5 md:left-3 lg:left-4 xl:left-[1.375rem];
        }
        .slick-next {
            @apply right-[0.3125rem] md:right-3 lg:right-4 xl:right-6;
        }
    }
    .banner-inner-content {
        @apply flex flex-col md:max-w-[56.375rem] gap-y-2.5 md:gap-y-[0.9375rem] md:mx-auto;
        .review-stars {
            @apply block mx-auto;
        }
        .heading {
            @apply md:max-w-[38.625rem] md:mx-auto;
        }
        .content {
            @apply text-lg leading-[1.8125rem] text-grey-1 font-medium;
        }
        .text-link {
            @apply self-center leading-6 after:bottom-0.5 md:leading-7 md:after:bottom-[0.3125rem];
        }
    }
}
.cms-product-carousel {
    @apply py-8 pl-6 pr-0 min-h-[9.375rem] md:py-10 md:px-6 lg:px-9 md:min-h-[25rem];
    &.carousel-with-content {
        @apply pt-0 pb-9 px-0 md:py-10 lg:pb-[3.125rem] md:pl-6 md:pr-0;
        .content-column {
            @apply pt-8 pb-2.5 mb-1 md:mb-0 md:py-0;
        }
        &.bestseller-carousel {
            div[class*="buttons_root"] button.btn-primary {
                @apply min-w-[11.5rem] md:min-w-0;
            }
            .pagebuilder-slider {
                &.progress-bar-slider {
                    .slick-slider {
                        .slick-dots li:not(.slick-active) button {
                            @apply bg-blue-2;
                        }
                    }
                }
            }
            .content-column {
                @apply pl-[1.125rem] -mb-2 md:mb-0 md:pl-4 lg:pl-6 xl:pl-[2.375rem];
            }
        }
        &.top-seller-carousel {
            div[class*="buttons_root"] button.btn-primary {
                @apply min-w-[11.5rem];
            }
            .content-column {
                @apply lg:pl-[1.3125rem];
            }
        }
    }
    .content-column {
        @apply gap-y-2.5 pl-6 pr-7 mb-3.5 md:mb-0 md:max-w-[38.96%] md:min-w-[38.96%] md:mr-[2%] md:px-4 lg:pr-5 lg:pl-6 xl:pl-[2.375rem] md:gap-y-4 xl:gap-y-[1.5625rem] md:mt-2.5;
        .pb-text-root {
            @apply flex flex-col gap-y-2.5 md:gap-y-4 xl:gap-y-[1.5625rem];
        }
    }
    .sub-heading {
        @apply text-lg leading-[1.6875rem] font-primary font-extrabold capitalize;
    }
    .content {
        @apply text-sm leading-6 md:text-lg md:leading-[1.8125rem] font-primary font-medium;
    }
    div[class*="buttons_root"] button.btn-primary {
        @apply xl:mb-10;
    }
    .product-column {
        @apply md:pl-[0.5625rem] md:relative md:max-w-[59.04%] md:min-w-[59.04%];
        .slick-slider {
            @apply md:static;
            .slick-track {
                &:has(.slick-current:last-child:not(:first-child)) {
                    @apply -left-6 md:-left-5;
                }
                &:has(.slick-current:nth-last-child(2):not(:first-child)) {
                    @apply md:-left-5;
                }
            }
        }
    }
    div[class*="row_contained"] {
        @apply max-w-[90rem] mx-auto;
    }
    .pagebuilder-slider {
        &.progress-bar-slider {
            @apply pl-6 pr-0 md:px-0;
            .slick-slider {
                .slick-prev, .slick-next {
                    top: calc(50% - 0.6875rem);
                    @screen md {
                        top: calc(50% - 1.0625rem);
                    }
                }
                .slick-next {
                    &.slick-disabled {
                        ~ .slick-dots {
                            li:last-child {
                                button {
                                    @apply bg-blue-1;
                                }
                            }
                        }
                    }
                }
                .slick-dots {
                    @apply mt-4 ml-0.5 mr-[1.5625rem] md:mt-5 md:ml-0.5 md:mr-6 lg:mr-8 xl:mr-12;
                }
            }
        }
    }
    .slick-slider {
        .slick-list {
            @apply py-2.5 -mx-[0.3125rem] md:-mx-2.5;
        }
        .slick-track {
            @apply flex;
            &:has(.slick-current:last-child:not(:first-child)) {
                @apply -left-6 md:left-0;
            }
        }
        .slick-slide {
            @apply mx-[0.3125rem] md:mx-2.5 h-auto p-0;
            > div, > div > div {
                @apply h-full;
            }
        }
        .slick-prev {
            @apply -left-3.5 md:-left-[1.125rem];
        }
        .slick-next {
            @apply right-[0.3125rem] md:right-3;
        }
    }
    .productitem-block {
        @apply py-2.5 md:py-0 md:px-2.5;
        .productimg-link {
            @apply md:pb-[1.3125rem];
        }
        .productimage-block {
            @apply md:-mx-2.5 md:w-auto;
        }
        .productitem-name {
            @apply md:px-2.5;
        }
        .productitem-details {
            @apply md:-mx-2.5 md:max-w-none;
            .product-price {
                @apply md:text-base lg:text-lg lg:leading-6;
            }
        }
    }
}
/* global class styles of cms pages */
.d-none {
    @apply hidden;
}
.d-sm-none {
    @apply sm:hidden;
}
.d-md-none {
    @apply md:hidden;
}
.d-lg-none {
    @apply lg:hidden;
}
.d-xl-none {
    @apply xl:hidden;
}
.d-block {
    @apply block;
}
.d-sm-block {
    @apply sm:block;
}
.d-md-block {
    @apply md:block;
}
.d-lg-block {
    @apply lg:block;
}
.d-xl-block {
    @apply xl:block;
}
.position-relative {
    @apply relative;
}
.align-items-center {
    @apply items-center;
}
.row {
    @apply flex flex-wrap -mx-[0.9375rem];
}
.no-gutters {
    @apply mx-0;
}
.no-gutters>.col,.no-gutters>[class*=col-] {
    @apply px-0
}
.row .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto,
.col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto,
.col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto,
.col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto,
.col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    @apply relative w-full px-[0.9375rem];
}
.row .col {
    @apply basis-0 flex-grow max-w-full;
}
.row-cols-1>* {
    @apply basis-full max-w-full;
}
.row-cols-2>* {
    @apply basis-1/2 max-w-[50%];
}
.row-cols-3>* {
    @apply basis-1/3 max-w-[33.333333%];
}
.row-cols-4>* {
    @apply basis-1/4 max-w-[25%];
}
.row-cols-5>* {
    @apply basis-1/5 max-w-[20%];
}
.row-cols-6>* {
    @apply basis-1/6 max-w-[16.666667%];
}
.col-auto {
    @apply basis-auto w-auto max-w-full;
}
.col-1 {
    @apply basis-1/12 max-w-[8.333333%];
}
.col-2 {
    @apply basis-2/12 max-w-[16.666667%];
}
.col-3 {
    @apply basis-3/12 max-w-[25%];
}
.col-4 {
    @apply basis-4/12 max-w-[33.333333%];
}
.col-5 {
    @apply basis-5/12 max-w-[41.666667%];
}
.col-6 {
    @apply basis-6/12 max-w-[50%];
}
.col-7 {
    @apply basis-7/12 max-w-[58.333333%];
}
.col-8 {
    @apply basis-8/12 max-w-[66.666667%];
}
.col-9 {
    @apply basis-9/12 max-w-[75%];
}
.col-10 {
    @apply basis-10/12 max-w-[83.333333%];
}
.col-11 {
    @apply basis-11/12 max-w-[91.666667%];
}
.col-12 {
    @apply basis-full max-w-full;
}
.offset-1 {
    @apply ml-[8.333333%];
}
.offset-2 {
    @apply ml-[16.666667%];
}
.offset-3 {
    @apply ml-[25%];
}
.offset-4 {
    @apply ml-[33.333333%];
}
.offset-5 {
    @apply ml-[41.666667%];
}
.offset-6 {
    @apply ml-[50%];
}
.offset-7 {
    @apply ml-[58.333333%];
}
.offset-8 {
    @apply ml-[66.666667%];
}
.offset-9 {
    @apply ml-[75%];
}
.offset-10 {
    @apply ml-[83.333333%];
}
.offset-11 {
    @apply ml-[91.666667%];
}
@screen sm {
    .col-sm {
        @apply basis-0 flex-grow max-w-full;
    }
    .row-cols-sm-1>* {
        @apply basis-full max-w-full;
    }
    .row-cols-sm-2>* {
        @apply basis-1/2 max-w-[50%];
    }
    .row-cols-sm-3>* {
        @apply basis-1/3 max-w-[33.333333%];
    }
    .row-cols-sm-4>* {
        @apply basis-1/4 max-w-[25%];
    }
    .row-cols-sm-5>* {
        @apply basis-1/5 max-w-[20%];
    }
    .row-cols-sm-6>* {
        @apply basis-1/6 max-w-[16.666667%];
    }
    .col-sm-auto {
        @apply basis-auto w-auto max-w-full;
    }
    .col-sm-1 {
        @apply basis-1/12 max-w-[8.333333%];
    }
    .col-sm-2 {
        @apply basis-2/12 max-w-[16.666667%];
    }
    .col-sm-3 {
        @apply basis-3/12 max-w-[25%];
    }
    .col-sm-4 {
        @apply basis-4/12 max-w-[33.333333%];
    }
    .col-sm-5 {
        @apply basis-5/12 max-w-[41.666667%];
    }
    .col-sm-6 {
        @apply basis-6/12 max-w-[50%];
    }
    .col-sm-7 {
        @apply basis-7/12 max-w-[58.333333%];
    }
    .col-sm-8 {
        @apply basis-8/12 max-w-[66.666667%];
    }
    .col-sm-9 {
        @apply basis-9/12 max-w-[75%];
    }
    .col-sm-10 {
        @apply basis-10/12 max-w-[83.333333%];
    }
    .col-sm-11 {
        @apply basis-11/12 max-w-[91.666667%];
    }
    .col-sm-12 {
        @apply basis-full max-w-full;
    }
    .order-sm-first {
        @apply order-first
    }
    .order-sm-last {
        @apply order-last
    }
    .order-sm-0 {
        @apply order-none;
    }
    .order-sm-1 {
        @apply order-1;
    }
    .order-sm-2 {
        @apply order-2;
    }
    .order-sm-3 {
        @apply order-3;
    }
    .order-sm-4 {
        @apply order-4;
    }
    .order-sm-5 {
        @apply order-5;
    }
    .order-sm-6 {
        @apply order-6;
    }
    .order-sm-7 {
        @apply order-7;
    }
    .order-sm-8 {
        @apply order-8;
    }
    .order-sm-9 {
        @apply order-9;
    }
    .order-sm-10 {
        @apply order-10;
    }
    .order-sm-11 {
        @apply order-11;
    }
    .order-sm-12 {
        @apply order-12;
    }
    .offset-sm-0 {
        @apply ml-0;
    }
    .offset-sm-1 {
        @apply ml-[8.333333%];
    }
    .offset-sm-2 {
        @apply ml-[16.666667%];
    }
    .offset-sm-3 {
        @apply ml-[25%];
    }
    .offset-sm-4 {
        @apply ml-[33.333333%];
    }
    .offset-sm-5 {
        @apply ml-[41.666667%];
    }
    .offset-sm-6 {
        @apply ml-[50%];
    }
    .offset-sm-7 {
        @apply ml-[58.333333%];
    }
    .offset-sm-8 {
        @apply ml-[66.666667%];
    }
    .offset-sm-9 {
        @apply ml-[75%];
    }
    .offset-sm-10 {
        @apply ml-[83.333333%];
    }
    .offset-sm-11 {
        @apply ml-[91.666667%];
    }
}
@screen md {
    .col-md {
        @apply basis-0 flex-grow max-w-full;
    }
    .row-cols-md-1>* {
        @apply basis-full max-w-full;
    }
    .row-cols-md-2>* {
        @apply basis-1/2 max-w-[50%];
    }
    .row-cols-md-3>* {
        @apply basis-1/3 max-w-[33.333333%];
    }
    .row-cols-md-4>* {
        @apply basis-1/4 max-w-[25%];
    }
    .row-cols-md-5>* {
        @apply basis-1/5 max-w-[20%];
    }
    .row-cols-md-6>* {
        @apply basis-1/6 max-w-[16.666667%];
    }
    .col-md-auto {
        @apply basis-auto w-auto max-w-full;
    }
    .col-md-1 {
        @apply basis-1/12 max-w-[8.333333%];
    }
    .col-md-2 {
        @apply basis-2/12 max-w-[16.666667%];
    }
    .col-md-3 {
        @apply basis-3/12 max-w-[25%];
    }
    .col-md-4 {
        @apply basis-4/12 max-w-[33.333333%];
    }
    .col-md-5 {
        @apply basis-5/12 max-w-[41.666667%];
    }
    .col-md-6 {
        @apply basis-6/12 max-w-[50%];
    }
    .col-md-7 {
        @apply basis-7/12 max-w-[58.333333%];
    }
    .col-md-8 {
        @apply basis-8/12 max-w-[66.666667%];
    }
    .col-md-9 {
        @apply basis-9/12 max-w-[75%];
    }
    .col-md-10 {
        @apply basis-10/12 max-w-[83.333333%];
    }
    .col-md-11 {
        @apply basis-11/12 max-w-[91.666667%];
    }
    .col-md-12 {
        @apply basis-full max-w-full;
    }
    .order-md-first {
        @apply order-first
    }
    .order-md-last {
        @apply order-last
    }
    .order-md-0 {
        @apply order-none;
    }
    .order-md-1 {
        @apply order-1;
    }
    .order-md-2 {
        @apply order-2;
    }
    .order-md-3 {
        @apply order-3;
    }
    .order-md-4 {
        @apply order-4;
    }
    .order-md-5 {
        @apply order-5;
    }
    .order-md-6 {
        @apply order-6;
    }
    .order-md-7 {
        @apply order-7;
    }
    .order-md-8 {
        @apply order-8;
    }
    .order-md-9 {
        @apply order-9;
    }
    .order-md-10 {
        @apply order-10;
    }
    .order-md-11 {
        @apply order-11;
    }
    .order-md-12 {
        @apply order-12;
    }
    .offset-md-0 {
        @apply ml-0;
    }
    .offset-md-1 {
        @apply ml-[8.333333%];
    }
    .offset-md-2 {
        @apply ml-[16.666667%];
    }
    .offset-md-3 {
        @apply ml-[25%];
    }
    .offset-md-4 {
        @apply ml-[33.333333%];
    }
    .offset-md-5 {
        @apply ml-[41.666667%];
    }
    .offset-md-6 {
        @apply ml-[50%];
    }
    .offset-md-7 {
        @apply ml-[58.333333%];
    }
    .offset-md-8 {
        @apply ml-[66.666667%];
    }
    .offset-md-9 {
        @apply ml-[75%];
    }
    .offset-md-10 {
        @apply ml-[83.333333%];
    }
    .offset-md-11 {
        @apply ml-[91.666667%];
    }
}
@screen lg {
    .col-lg {
        @apply basis-0 flex-grow max-w-full;
    }
    .row-cols-lg-1>* {
        @apply basis-full max-w-full;
    }
    .row-cols-lg-2>* {
        @apply basis-1/2 max-w-[50%];
    }
    .row-cols-lg-3>* {
        @apply basis-1/3 max-w-[33.333333%];
    }
    .row-cols-lg-4>* {
        @apply basis-1/4 max-w-[25%];
    }
    .row-cols-lg-5>* {
        @apply basis-1/5 max-w-[20%];
    }
    .row-cols-lg-6>* {
        @apply basis-1/6 max-w-[16.666667%];
    }
    .col-lg-auto {
        @apply basis-auto w-auto max-w-full;
    }
    .col-lg-1 {
        @apply basis-1/12 max-w-[8.333333%];
    }
    .col-lg-2 {
        @apply basis-2/12 max-w-[16.666667%];
    }
    .col-lg-3 {
        @apply basis-3/12 max-w-[25%];
    }
    .col-lg-4 {
        @apply basis-4/12 max-w-[33.333333%];
    }
    .col-lg-5 {
        @apply basis-5/12 max-w-[41.666667%];
    }
    .col-lg-6 {
        @apply basis-6/12 max-w-[50%];
    }
    .col-lg-7 {
        @apply basis-7/12 max-w-[58.333333%];
    }
    .col-lg-8 {
        @apply basis-8/12 max-w-[66.666667%];
    }
    .col-lg-9 {
        @apply basis-9/12 max-w-[75%];
    }
    .col-lg-10 {
        @apply basis-10/12 max-w-[83.333333%];
    }
    .col-lg-11 {
        @apply basis-11/12 max-w-[91.666667%];
    }
    .col-lg-12 {
        @apply basis-full max-w-full;
    }
    .order-lg-first {
        @apply order-first
    }
    .order-lg-last {
        @apply order-last
    }
    .order-lg-0 {
        @apply order-none;
    }
    .order-lg-1 {
        @apply order-1;
    }
    .order-lg-2 {
        @apply order-2;
    }
    .order-lg-3 {
        @apply order-3;
    }
    .order-lg-4 {
        @apply order-4;
    }
    .order-lg-5 {
        @apply order-5;
    }
    .order-lg-6 {
        @apply order-6;
    }
    .order-lg-7 {
        @apply order-7;
    }
    .order-lg-8 {
        @apply order-8;
    }
    .order-lg-9 {
        @apply order-9;
    }
    .order-lg-10 {
        @apply order-10;
    }
    .order-lg-11 {
        @apply order-11;
    }
    .order-lg-12 {
        @apply order-12;
    }
    .offset-lg-0 {
        @apply ml-0;
    }
    .offset-lg-1 {
        @apply ml-[8.333333%];
    }
    .offset-lg-2 {
        @apply ml-[16.666667%];
    }
    .offset-lg-3 {
        @apply ml-[25%];
    }
    .offset-lg-4 {
        @apply ml-[33.333333%];
    }
    .offset-lg-5 {
        @apply ml-[41.666667%];
    }
    .offset-lg-6 {
        @apply ml-[50%];
    }
    .offset-lg-7 {
        @apply ml-[58.333333%];
    }
    .offset-lg-8 {
        @apply ml-[66.666667%];
    }
    .offset-lg-9 {
        @apply ml-[75%];
    }
    .offset-lg-10 {
        @apply ml-[83.333333%];
    }
    .offset-lg-11 {
        @apply ml-[91.666667%];
    }
}
@screen xl {
    .col-xl {
        @apply basis-0 flex-grow max-w-full;
    }
    .row-cols-xl-1>* {
        @apply basis-full max-w-full;
    }
    .row-cols-xl-2>* {
        @apply basis-1/2 max-w-[50%];
    }
    .row-cols-xl-3>* {
        @apply basis-1/3 max-w-[33.333333%];
    }
    .row-cols-xl-4>* {
        @apply basis-1/4 max-w-[25%];
    }
    .row-cols-xl-5>* {
        @apply basis-1/5 max-w-[20%];
    }
    .row-cols-xl-6>* {
        @apply basis-1/6 max-w-[16.666667%];
    }
    .col-xl-auto {
        @apply basis-auto w-auto max-w-full;
    }
    .col-xl-1 {
        @apply basis-1/12 max-w-[8.333333%];
    }
    .col-xl-2 {
        @apply basis-2/12 max-w-[16.666667%];
    }
    .col-xl-3 {
        @apply basis-3/12 max-w-[25%];
    }
    .col-xl-4 {
        @apply basis-4/12 max-w-[33.333333%];
    }
    .col-xl-5 {
        @apply basis-5/12 max-w-[41.666667%];
    }
    .col-xl-6 {
        @apply basis-6/12 max-w-[50%];
    }
    .col-xl-7 {
        @apply basis-7/12 max-w-[58.333333%];
    }
    .col-xl-8 {
        @apply basis-8/12 max-w-[66.666667%];
    }
    .col-xl-9 {
        @apply basis-9/12 max-w-[75%];
    }
    .col-xl-10 {
        @apply basis-10/12 max-w-[83.333333%];
    }
    .col-xl-11 {
        @apply basis-11/12 max-w-[91.666667%];
    }
    .col-xl-12 {
        @apply basis-full max-w-full;
    }
    .order-xl-first {
        @apply order-first
    }
    .order-xl-last {
        @apply order-last
    }
    .order-xl-0 {
        @apply order-none;
    }
    .order-xl-1 {
        @apply order-1;
    }
    .order-xl-2 {
        @apply order-2;
    }
    .order-xl-3 {
        @apply order-3;
    }
    .order-xl-4 {
        @apply order-4;
    }
    .order-xl-5 {
        @apply order-5;
    }
    .order-xl-6 {
        @apply order-6;
    }
    .order-xl-7 {
        @apply order-7;
    }
    .order-xl-8 {
        @apply order-8;
    }
    .order-xl-9 {
        @apply order-9;
    }
    .order-xl-10 {
        @apply order-10;
    }
    .order-xl-11 {
        @apply order-11;
    }
    .order-lg-12 {
        @apply order-12;
    }
    .offset-xl-0 {
        @apply ml-0;
    }
    .offset-xl-1 {
        @apply ml-[8.333333%];
    }
    .offset-xl-2 {
        @apply ml-[16.666667%];
    }
    .offset-xl-3 {
        @apply ml-[25%];
    }
    .offset-xl-4 {
        @apply ml-[33.333333%];
    }
    .offset-xl-5 {
        @apply ml-[41.666667%];
    }
    .offset-xl-6 {
        @apply ml-[50%];
    }
    .offset-xl-7 {
        @apply ml-[58.333333%];
    }
    .offset-xl-8 {
        @apply ml-[66.666667%];
    }
    .offset-xl-9 {
        @apply ml-[75%];
    }
    .offset-xl-10 {
        @apply ml-[83.333333%];
    }
    .offset-xl-11 {
        @apply ml-[91.666667%];
    }
}
/* Common CMS Block Styles */
